import React from 'react'

const Privacy = () => {
    return (
        <div>
            <div className="settings_wrap ">
                <div className="row">
                    <div className="col-12">
                        <div className="settings_head">
                            <h2>Privacy</h2>
                        </div>
                    </div>
                    <div className="col-7 mt-5">
                        <div className="settings_content">
                            <h3 className='mb-1' style={{ fontWeight: "500" }}>Custom Warning</h3>
                            <p className='mb-2 para_text2 f-13'>Always Displayed at the top of the chat</p>
                        </div>
                        <div className="setting_input message">
                            <div className="input-outer">
                                <div className="input-group" style={{ flexWrap: "Nowrap" }}>
                                    <textarea
                                        className="common-inp alt"
                                        type="email"
                                        rows="4"
                                        placeholder="Using this clone for financial, medical, health, or psychological advice is not recommended neither Delphi nor the clone owner assume liability for decisions based on such information..."
                                    />

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7 mt-5">
                        <div className="d-flex justify-content-between align-items-center">
                            <div className=" settings_content col-10">
                                <h3 style={{ fontWeight: "500" }}>Show Citations</h3>
                                <p className="para_text2 f-13">Permits  users to view sources</p>
                            </div>
                            <div className="opt-select">
                                <label className="switch">
                                    <input
                                        type="checkbox"
                                    // checked={selectedSlide.faceNarration.enable}
                                    // onChange={handleChangeEnable}
                                    />
                                    <span className="slider round" style={{ background: "#e4e4e7" }}></span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7 mt-5">
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="settings_content col-10">
                                <h3 style={{ fontWeight: "500" }}>Disable Delphi Talk Page</h3>
                                <p className="para_text2 f-13">Your Delphi talk page, located at <span style={{ color: "#ff6a27" }}>delphi.ai/dana</span>, will be disabled for all users.Upgrade to <span style={{ color: "#ff6a27" }}>Prodigy</span> to disable</p>
                            </div>
                            <div className="opt-select">
                                <label className="switch">
                                    <input
                                        type="checkbox"
                                    // checked={selectedSlide.faceNarration.enable}
                                    // onChange={handleChangeEnable}
                                    />
                                    <span className="slider round" style={{ background: "#e4e4e7" }}></span>
                                </label>
                            </div>
                        </div>
                        <div className="d-flex justify-content-end mt-5">
                            <button className="theme-btn me-0"><span>Save</span> </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Privacy;
