import React, { useState } from 'react'

const Messages = () => {
    const [sliderValue, setSliderValue] = useState(1);

    const handleSliderChange = (event) => {
        const value = parseInt(event.target.value);
        setSliderValue(value);
    };
    return (
        <div className='row'>
            <div className="message_wrap">
                <div className="col-12">
                    <div className="settings_head">
                        <h2>Message</h2>
                    </div>
                </div>
                <div className="col-lg-7 mt-5">
                    <div className="settings_content ">
                        <h3 className='mb-1 ' style={{ fontWeight: "500" }}>Initial Message</h3>
                        <p className='mb-2 para_text2 f-13'>How your clone start every conversation</p>
                    </div>
                    <div className="setting_input message">
                        <div className="input-outer">
                            <div className="input-group" style={{ flexWrap: "Nowrap" }}>
                                <textarea
                                    className="common-inp alt"
                                    rows="4"
                                    type="email"
                                    placeholder="Hy I am Dana. How can i Help ?"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-7 mt-5">
                    <div className="settings_content ">
                        <h3 className='mb-1' style={{ fontWeight: "500" }}>Message on No Known Answere </h3>
                        <p className='mb-2 para_text2 f-13'>What your clone will say when it does not know how to respond to the user's query.</p>
                    </div>
                    <div className="setting_input msg">
                        <div className="input-outer">
                            <div className="input-group" style={{ flexWrap: "Nowrap" }}>
                                <textarea
                                    className="common-inp alt"
                                    rows="4"
                                    type="email"
                                    placeholder="Sorry not sure about that one. Fell free to short me an email at..."
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-7 mt-5">
                    <div className="settings_content">
                        <h3 className='mb-1' style={{ fontWeight: "500" }}>Message before Email Capture </h3>
                        <p className='mb-2 para_text2 f-13'>How many messages a user can send before being required to login and provide their email..</p>
                    </div>
                    <div className="inp_radio_wrap">
                        <span style={{ color: "#ff6a27", fontWeight: "600" }}>{sliderValue}</span>

                        <input type="range" className=" mt-2"
                            min="0"
                            max="10"
                            id="customRange2"
                            value={sliderValue}
                            onChange={handleSliderChange}

                        />
                    </div>
                </div>
                <div className="col-lg-7">
                    <div className=" mt-3">
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="info mt-3 col-10">
                                <h3 style={{ color: "#000", fontWeight: "500" }}>Enable Voice Calling</h3>
                                <p className="para_text2 f-13">Allows users to call clone. A voice must be uploaded.</p>
                            </div>
                            <div className="opt-select">
                                <label className="switch">
                                    <input
                                        type="checkbox"
                                    // checked={selectedSlide.faceNarration.enable}
                                    // onChange={handleChangeEnable}
                                    />
                                    <span className="slider round" style={{ background: "#e4e4e7" }}></span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className=" mt-3">
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="info mt-3 col-10">
                                <h3 style={{ color: "#000", fontWeight: "500" }}>Enable Image Sending</h3>
                                <p className="para_text2 f-13">Allow users to send images to your clone</p>
                            </div>
                            <div className="opt-select">
                                <label className="switch">
                                    <input
                                        type="checkbox"
                                    // checked={selectedSlide.faceNarration.enable}
                                    // onChange={handleChangeEnable}
                                    />
                                    <span className="slider round" style={{ background: "#e4e4e7" }}></span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-end mt-3">
                        <button className="theme-btn me-0"><span>Save</span>  </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Messages
