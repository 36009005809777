import React from 'react'
import plan from '../../../../../src/assets/images/SocialIcons/plan.png';
import { FaChevronRight } from 'react-icons/fa';


const BillingPlan = () => {
    return (
        <>
            <div className="studio_setting ps-3">
                <div className="row">
                    <div className="col-12">
                        <div className="settings_head">
                            <h2>Billing & Plan</h2>
                        </div>

                        <div className="yourPlan_wrapper mt-4">
                            <h4 className='f-13 mb-2'>Your Plan</h4>
                            <div className="head" style={{ color: "#18181b", fontWeight: "400", height: "32px" }}> <span className=' ms-3 ' style={{ marginTop: '4px' }}><img src={plan} alt="" className='me-2' /> Advanced </span></div>
                            <p>Your <b className='text-dark'>monthly</b> subscription will automatically renew on <b className='text-dark'>April 13th, 2024</b>  for <b className='text-dark'>$119</b> .</p>
                            <div className='text-end mt-3'>
                                <button className='theme-btn f-13 me-3'><span>Manage</span></button>
                            </div>
                        </div>

                        <div className="payment_wrap mt-5">
                            <h2 className='f-18'>Payment Method</h2>
                            <button className='theme-btn f-13 mt-2'><span>Update</span></button>
                            <h2 className='f-18 mt-5'>Billing History</h2>
                            <div style={{ maxWidth: "576px" }}>
                                <div className="bill_history">
                                    <div>
                                        <span style={{ color: "#71717a" }}>March 13th, 2024</span>
                                        <span style={{ color: "#a1a1aa45", display: "inline-block" }} className='ms-2 me-2'>|</span>
                                        <span className='text-dark'>$119</span>
                                    </div>
                                    <span>
                                        <FaChevronRight className='f-13' style={{ color: "#71717a" }} />
                                    </span>

                                </div>
                                <hr className='' style={{ opacity: "1", color: "#a1a1aa45", marginTop: "5px" }} />
                            </div>
                            <div style={{ maxWidth: "576px" }}>
                                <div className="bill_history">
                                    <div>
                                        <span style={{ color: "#71717a" }}>March 13th, 2024</span>
                                        <span style={{ color: "#a1a1aa45", display: "inline-block" }} className='ms-2 me-2'>|</span>
                                        <span className='text-dark'>$119</span>
                                    </div>
                                    <span>
                                        <FaChevronRight className='f-13' style={{ color: "#71717a" }} />
                                    </span>

                                </div>
                                <hr className='' style={{ opacity: "1", color: "#a1a1aa45", marginTop: "5px" }} />
                            </div>

                        </div>

                    </div>

                </div>
            </div >
        </>
    )
}

export default BillingPlan
