import React, { useEffect, useState } from 'react'
import { fetchData } from '../../../Redux/Actions/CommonActions';
import { useDispatch } from 'react-redux';

const Topics = ({ handleUpdate, data, setData,loader }) => {
    const dispatch = useDispatch()
    const [topics, setTopics] = useState([])

    const handleAdd = (name) => {
        if (data.topics.includes(name)) {
            let newTopics = data.topics.filter((curElem) => {
                return curElem !== name
            })
            setData((draft) => {
                draft.topics = newTopics
            })
        } else {
            setData((draft) => {
                draft.topics.push(name)
            })
        }
    }


    const fetchTopics = () => {
        dispatch(fetchData("fetch-topics", {}, setTopics))
    }

    useEffect(() => {
        fetchTopics()
    }, [])


    return (
        <div>
            <h3>Select topics for your clone</h3>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
            <div className='topic-list'>
                <ul>
                    {topics.length > 0 ?
                        topics.map((curElem, index) => {
                            return (
                                <li key={index}>
                                    <div className={`topic-list-single ${data.topics.includes(curElem.name) ? 'active-topic' : ""}`} onClick={() => handleAdd(curElem.name)}>
                                        <div className='topic-list-cont text-capitalize '><i className={`fa-solid ${curElem.icon}`}></i>{curElem.name}</div>
                                    </div>
                                </li>
                            )
                        })

                        : ""}
                 
                    {/* <li>
                        <div className='topic-list-single'>
                            <div className='topic-list-cont'><BsBook /> Education</div>
                            <input type="checkbox" name="" id="" />
                        </div>
                    </li> */}
                </ul>
            </div>
            <div className='input-wrap'>
                <button className='theme-btn full' onClick={() => handleUpdate(null)}><span>{loader.submit ? <>Continue <i className='fa fa-spin fa-spinner' /></> : "Continue"} </span></button>
            </div>
        </div>
    )
}

export default Topics