import React, { useState } from 'react'
import { FaPlus } from 'react-icons/fa6'
import note from '../../../../assets/images/note.png'
const AlertsAudience = () => {
    return (
        <>
            <div className="train_content_wrap" style={{ marginTop: "30px" }}>
                <div className="train_flex" >
                    <div className="train_content_head justify-content-center align-items-start flex-column">
                        <h2>Alerts </h2>
                        <p>Get notified on topics of interests </p>
                    </div>
                    <div className="d-flex mt-lg-0 mt-3 ms-2">
                        <button className='theme-btn'><span>Add Alert <FaPlus /></span></button>
                    </div>
                </div>

                <div className="train_table">
                    <div className="table-area mt-3">
                        <div className="table-responsive">
                            <table className="table widget-table">
                                <tr>
                                    <th>Content</th>
                                    <th>Uploaded</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                                <tr>
                                    <td>Mark</td>
                                    <td>Otto</td>
                                    <td>@mdo</td>
                                    <td>@mdo</td>
                                </tr>
                                <tr>
                                    <td>Jacob</td>
                                    <td>Thornton</td>
                                    <td>@fat</td>
                                    <td>@fat</td>

                                </tr>
                                <tr>
                                    <td>Larry the Bird</td>
                                    <td>@twitter</td>
                                    <td>@twitter</td>
                                    <td>@twitter</td>
                                </tr>


                            </table>
                            <div className="no_content">
                                <img className='mt-3' src={note} alt="" />
                                <b className='mt-2'>Create an alert to get started
                                </b>
                                <span style={{ color: "#a1a1aa" }}> Get notified on topics of interest.</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default AlertsAudience
