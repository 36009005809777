import React from 'react';
import noteImg from '../../../../../src/assets/images/note.png';
import { IoAddOutline } from "react-icons/io5";
import { IoIosArrowForward } from "react-icons/io";

const CloneInstances = () => {
    return (

        <div className="settings_wrap ps-3">
            <div className="row">
                <div className="col-lg-7">
                    <div className="settings_head d-flex justify-content-between align-items-center">
                        <div className="instance_title">
                            <h2>Clone Instances</h2>
                            <p className='para_text2 f-13'>Learn more about instances <span style={{ color: "#ff6a27" }}>here</span></p>
                        </div>
                        <div className="btn-wrap">
                            <button className='theme-btn'><span>Create Instance<IoAddOutline className=' addIcon' /></span>  </button>
                        </div>

                    </div>
                </div>
                <div className="col-lg-7 mt-4">
                    <div className="img-wrap text-center">
                        <img src={noteImg} alt="note" className='img-fluid' />
                    </div>
                    <div className="instance_info text-center mt-2">
                        <h3 style={{ fontSize: "22px" }} className='para_text2'>Create an Instance of your Clone</h3>
                        <p className='para_text f-13 mt-1'>Clone Instance allow you to create multiple versions of your clone.</p>
                    </div>

                </div>
            </div>

        </div>
    )
}

export default CloneInstances
