import React, { useState } from 'react'
import { MdModeEditOutline } from 'react-icons/md'
import dummyImg from "../../../assets/images/dummyImage.png"
import { onGenerateAi } from '../../../Redux/Actions/CommonActions'
import { useDispatch } from 'react-redux'
import { themeColor } from '../../../Global/Global'

const FinalCloneBox = ({ handleUpdate, data, setData, onImageChange, loader }) => {
    const dispatch = useDispatch()
    const [image, setImage] = useState("")
    const [text, setText] = useState("")
    const [loaderGen, setLoaderGen] = useState({
        generate: false
    })
    const generateImage = () => {
        let data = {
            text
        }
        setLoaderGen({
            ...loaderGen,
            generate: true
        })
        dispatch(onGenerateAi(data, setImage, loaderGen, setLoaderGen))
    }

    const handleAdd = () => {
        setData((draft) => {
            draft.image = image
        })
        setImage("")
    }

    return (
        <div>
            <h3 className='text-center'>This is how your clone will look.</h3>

            <div className='clone-img block' style={{ position: "relative" }}>
                <div className='clone-img-holder'>
                    <img src={data.image ? data.image : dummyImg} alt="" />
                    <div className='clone-img-up'>
                        <MdModeEditOutline style={{ color: "#fff", cursor: "pointer" }} />
                        <input
                            type="file"
                            onChange={(e) => onImageChange(e)}
                        />
                    </div>
                </div>

            </div>

            <hr className='mt-5' />

            <div className='input-wrap'>
                <label className='label' htmlFor="">Generate Image from Prompt</label>
                <div className='input-outer'>
                    <textarea
                        className='common-inp alt'
                        rows="5"
                        placeholder='Image Description'
                        value={text}
                        onChange={(e) => setText(e.target.value)}
                    />
                </div>
                <div style={{ textAlign: "right" }}>
                    <button className='theme-btn ' onClick={generateImage}><span>{loaderGen.generate ? <> Generating <i className='fa fa-spin fa-spinner' /></> : "Generate AI Image"}</span></button>
                </div>
            </div>
            {image ?
                <div className='clone-img block'>
                    <div style={{ height: 200, width: 200, margin: "auto", display: "flex", alignItems: "center", border: `1px solid ${themeColor}`, borderRadius: 10, padding: 2 }}>
                        <img src={image} alt="" />
                    </div>
                    <button className='theme-btn mt-2' onClick={handleAdd}><span>Add</span></button>
                </div>
                : ""}

            <div className='input-wrap d-flex justify-content-between'>
                <button className='theme-btn full' onClick={() => handleUpdate("final")}><span>{loader.submit ? <>Continue <i className='fa fa-spin fa-spinner' /></> : "Continue"}</span></button>
            </div>

        </div>
    )
}

export default FinalCloneBox