import React, { useEffect, useState } from 'react'
import { BsPlus } from 'react-icons/bs'
import { useDispatch } from 'react-redux'
import { fetchData } from '../../../Redux/Actions/CommonActions'
import { onAddCustomePurpose } from '../../../Redux/Actions/CloneActions'
import CustomePurposeRows from './CustomePurposeRows';

const Purpose = ({ data, setData, handleUpdate, loader }) => {
    const dispatch = useDispatch()
    const [text, setText] = useState("")
    const [purpose, setPurpose] = useState([])
    const [customePurpose, setCustomePurpose] = useState([])


    const handleAdd = (e, purpose) => {
        if (e.target.checked) {
            setData((draft) => {
                draft.purpose.push(purpose)
            })
        } else {
            setData((draft) => {
                draft.purpose = draft.purpose.filter((curElem) => {
                    return curElem !== purpose
                })
            })
        }
    }
    const addCustomePurpose = () => {
        let obj = {
            purpose: text,
            cId: data.id
        }
        dispatch(onAddCustomePurpose(obj, fetchCustomePurpose, setText))
    }


    const fetchCustomePurpose = () => {
        dispatch(fetchData("fetch-custom-purpose", {}, setCustomePurpose))
    }

    const fetchTopics = () => {
        dispatch(fetchData("fetch-purpose", {}, setPurpose))
    }

    useEffect(() => {
        fetchTopics()
        fetchCustomePurpose()
    }, [])

    return (
        <div>
            {/* <h3>What is your clone's purpose?</h3> */}
            <h1>What’s the goal of your clone?</h1>
            <p>This will shape its conversation style and the information it needs to be precise.</p>
            <div className='clone-purpose'>
                <ul>
                    {purpose.length > 0 ?
                        purpose.map((curElem, index) => {
                            return (
                                <li key={index}>
                                    <p>{curElem.purpose}</p>
                                    <label className='check-select'>
                                        <input type="checkbox" checked={data.purpose.includes(curElem.purpose)} onChange={(e) => handleAdd(e, curElem.purpose)} />
                                        <span className='check-select-mark'></span>
                                    </label>
                                </li>
                            )
                        })
                        : ""}
                    {customePurpose.length > 0 ?
                        customePurpose.map((curElem, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <CustomePurposeRows
                                        curElem={curElem}
                                        data={data}
                                        handleAdd={handleAdd}
                                        fetchCustomePurpose={fetchCustomePurpose}
                                    />
                                </React.Fragment>

                            )
                        })
                        : ""}
                </ul>
            </div>

            <div className='custom-purpose'>
                <div className='input-wrap'>
                    <div className='input-outer'>
                        <input
                            className='common-inp alt'
                            type="text"
                            placeholder='Add Custom Purpose'
                            value={text}
                            onChange={(e) => setText(e.target.value)}
                        />
                        <span className='add-purpose-btn' onClick={addCustomePurpose}><BsPlus /></span>
                    </div>
                </div>
            </div>

            <div className='input-wrap'>
                <button className='theme-btn full' onClick={() => handleUpdate(null)}><span>{loader.submit ? <>Continue <i className='fa fa-spin fa-spinner' /></> : "Continue"} </span></button>
            </div>
        </div>
    )
}

export default Purpose