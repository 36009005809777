import { commonAxios } from "../../Global/CommonAxios"
import { setAlert } from "./AlertActions"

export const onCreateClone = (data, handleNext, navigate, loader, setLoader) => (dispatch, getState) => {
    commonAxios("create-clone", data, dispatch, getState().auth.token).
        then((res) => {
            if (res.status) {
                navigate(`/create-clone?cId=${res.data}`)
                handleNext()
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                submit: false
            })
        }).catch((err) => {
            console.log(err.msg)
            dispatch(setAlert(err.msg, "danger"))
            setLoader({
                ...loader,
                submit: false
            })
        })
}

export const onUpdateClone = (data, handleNext, handleShow, loader, setLoader) => (dispatch, getState) => {
    commonAxios("update-clone", data, dispatch, getState().auth.token).
        then((res) => {
            if (res.status) {
                if (data.type === "final") {
                    handleShow()
                } else {
                    handleNext()
                }
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                submit: false
            })
        }).catch((err) => {
            console.log(err.msg)
            dispatch(setAlert(err.msg, "danger"))
            setLoader({
                ...loader,
                submit: false
            })
        })
}


export const onFetchClone = (data, setState) => (dispatch, getState) => {
    commonAxios("fetch-clone", data, dispatch, getState().auth.token).
        then((res) => {
            if (res.status) {
                let obj = { ...res.data }
                if (obj.topics === null) {
                    obj.topics = []
                }
                if (obj.purpose === null) {
                    obj.purpose = []
                }
                setState(obj)
            }
        }).catch((err) => {
            console.log(err.msg)
            dispatch(setAlert(err.msg, "danger"))
        })
}

export const onFetchCloneRedux = (data, loader, setLoader) => (dispatch, getState) => {
    commonAxios("fetch-clone", data, dispatch, getState().auth.token).
        then((res) => {
            if (res.status) {
                dispatch({ type: "ADD_CLONE_DATA", payload: res.data })
            }
            setLoader({
                ...loader,
                fetch: false
            })
        }).catch((err) => {
            console.log(err.msg)
            setLoader({
                ...loader,
                fetch: false
            })
            dispatch(setAlert(err.msg, "danger"))
        })
}

export const onFetchCloneStudio = (data, loader, setLoader) => (dispatch, getState) => {
    commonAxios("fetch-purpose", data, dispatch, getState().auth.token).
        then((res) => {
            if (res.status) {
                dispatch({ type: "ADD_CLONE_STUDIO", payload: res.data[0] })
            }
            setLoader({
                ...loader,
                fetch: false
            })
        }).catch((err) => {
            console.log(err.msg)
            setLoader({
                ...loader,
                fetch: false
            })
            dispatch(setAlert(err.msg, "danger"))
        })
}



export const onAddCustomePurpose = (data, fetchCustomePurpose, setText) => (dispatch, getState) => {
    commonAxios("add-custom-purpose", data, dispatch, getState().auth.token).
        then((res) => {
            if (res.status) {
                fetchCustomePurpose()
                setText("")
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
        }).catch((err) => {
            console.log(err.msg)
            dispatch(setAlert(err.msg, "danger"))
        })
}

export const onUpdateCustomePurpose = (data, fetchCustomePurpose, setActiveEdit) => (dispatch, getState) => {
    commonAxios("update-custom-purpose", data, dispatch, getState().auth.token).
        then((res) => {
            if (res.status) {
                fetchCustomePurpose()
                setActiveEdit({
                    active: false,
                    text: ""
                })
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
        }).catch((err) => {
            console.log(err.msg)
            dispatch(setAlert(err.msg, "danger"))
        })
}

export const onDeletePurpose = (data, fetchCustomePurpose, setSweet) => (dispatch, getState) => {
    commonAxios("delete-custom-purpose", data, dispatch, getState().auth.token).
        then((res) => {
            if (res.status) {
                fetchCustomePurpose()
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setSweet({
                enable: false,
                confirmButtonName: "Delete",
                loader: false
            })
        }).catch((err) => {
            console.log(err.msg)
            setSweet({
                enable: false,
                confirmButtonName: "Delete",
                loader: false
            })
            dispatch(setAlert(err.msg, "danger"))
        })
}


export const onFetchCloneLang = (setPollyLanguages) => (dispatch, getState) => {
    commonAxios("get-polly-languages", {}, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let arr = [...res.data.reverse()]
                arr.sort(function (a, b) {
                    return a.name.localeCompare(b.name)
                });
                // let vId = arr.find(({ code }) => code === langId)
                // if (vId) {
                //     fetchPollyVoices(vId.id, langId)
                // }
                setPollyLanguages(arr);
            }
        }).catch((err) => {
            console.log(err)
        })
}


export const onFetchVoice = (value, setPollyVoices, setLangData, langData, selectedSlide, langCode, setEngine) => (dispatch, getState) => {

    let formData = new FormData();
    formData.append('lang_code', value);
    let id = ["96"]
    commonAxios("get-artists", formData, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let obj = [...res.data]
                obj = obj.filter((curElem) => {
                    return !id.includes(curElem.id)
                })
                setPollyVoices(obj);
            }
        }).catch((err) => {
            console.log(err)
        })
}

export const onGenerateCloneTts = (data, ttsData, setTtsData, loader, setLoader, setTtsStatus) => (dispatch, getState) => {
    commonAxios("generate-tts-new", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setTtsStatus(true)
                setTtsData({
                    ...ttsData,
                    src: res.data.file_url,
                    duration: res.data.duration - 3,
                    meta: {
                        ...ttsData.meta,
                        language_id: data.language_id,
                        voice_id: data.voice_id,
                        engine: data.engine
                    }
                })
            } else {
                dispatch(setAlert(res.msg, 'danger'));
                setTtsStatus(false)
            }
            setLoader({
                ...loader,
                generate: false
            });
        }).catch((error) => {
            setTtsStatus(false)
            setLoader({
                ...loader,
                generate: false
            });
            console.log(error)
        })
}

export const onSaveVoice = (data, loader, setLoader) => (dispatch, getState) => {
    commonAxios("save-voice", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
                dispatch({ type: "SAVE_VOICE", payload: data })
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                save: false
            })
        }).catch((err) => {
            setLoader({
                ...loader,
                save: false
            })
            dispatch(setAlert(err.msg, "danger"))
            console.log(err)
        })
}


export const onSubmitInstruction = (data, setInstData) => (dispatch, getState) => {
    commonAxios("create-custom-instruction", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setInstData({
                    ...data,
                    id: res.data
                })
            }
        }).catch((err) => {
            console.log(err.msg)
        })
}


export const onUpdateInstruction = (data) => (dispatch, getState) => {
    commonAxios("update-custom-instruction", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                // fetchInstruction()
            }
        }).catch((err) => {
            console.log(err.msg)
        })
}

export const onDeleteInstruction = (data, fetchData, setSweet) => (dispatch, getState) => {
    commonAxios("delete-custom-instruction", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                fetchData()
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setSweet({
                enable: false,
                confirmButtonName: "Delete",
                loader: false
            })
        }).catch((err) => {
            console.log(err.msg)
            dispatch(setAlert(err.msg, "danger"))
            setSweet({
                enable: false,
                confirmButtonName: "Delete",
                loader: false
            })
        })
}

export const onCreateTopic = (data, state, setState, loader, setLoader) => (dispatch, getState) => {
    commonAxios("save-topic", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setState({ ...state, topicArray: [...state.topicArray, { id: res.data, topic: data.topic }] })
            }
            setLoader({ ...loader, create: false })
        }).catch((err) => {
            console.log(err.msg)
            setLoader({ ...loader, create: false })
        })
}
export const onFetchSaveTopics = (data, state, setState) => (dispatch, getState) => {
    commonAxios("fetch-topic", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setState({ ...state, topicArray: res.data })
            }
        }).catch((err) => {
            console.log(err.msg)
        })
}
export const onDeleteTopic = (data, state, setState, setSweet) => (dispatch, getState) => {
    commonAxios("delete-topic", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let arr = state.topicArray.filter((topic) => {
                    return +topic.id !== +data.id
                })
                setState({ ...state, topicArray: arr, selectedTopic: +state.selectedTopic === +data.id ? -1 : state.selectedTopic })
            }
            setSweet({
                enable: false,
                id: false,
                confirmButtonName: "Delete",
                loader: false
            })
        }).catch((err) => {
            console.log(err.msg)
            setSweet({
                enable: false,
                id: false,
                confirmButtonName: "Delete",
                loader: false
            })
        })
}
export const onCreateQuestion = (data, loader, setLoader, fetchQuestions, handleClose) => (dispatch, getState) => {
    commonAxios("create-suggested-question", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                fetchQuestions();
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({ ...loader, save: false });
            handleClose();
        }).catch((err) => {
            console.log(err.msg)
            dispatch(setAlert(err.msg, "danger"))
            setLoader({ ...loader, save: false });
            handleClose();
        })
}
export const onUpdateQuestion = (data, loader, setLoader, questions, setQuestions, handleClose) => (dispatch, getState) => {
    commonAxios("update-suggested-question", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
                let arr = questions.map((val) => {
                    if (+val.id === +data.id) {
                        return {
                            ...val,
                            questions: data.question,
                            tId: data.tId
                        }
                    } else {
                        return val
                    }
                })
                setQuestions(arr)
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            handleClose();
            setLoader({ ...loader, save: false });
        }).catch((err) => {
            console.log(err.msg)
            dispatch(setAlert(err.msg, "danger"))
            handleClose();
            setLoader({ ...loader, save: false });
        })
}

export const onFetchAllQuestion = (data, setQuestions, loader, setLoader) => (dispatch, getState) => {
    commonAxios("fetch-all-suggested-question", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setQuestions(res.data)
            }
            setLoader({ ...loader, fetch: false });
        }).catch((err) => {
            console.log(err.msg)
            dispatch(setAlert(err.msg, "danger"))
            setLoader({ ...loader, fetch: false });
        })
}

export const onDeleteVoice = (data, fetchVoice, setSweet) => (dispatch, getState) => {
    commonAxios("delete-voice", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                fetchVoice()
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setSweet({
                enable: false,
                confirmButtonName: "Delete",
                loader: false
            })
        }).catch((err) => {
            console.log(err.msg)
            setSweet({
                enable: false,
                confirmButtonName: "Delete",
                loader: false
            })
        })


}

export const onUpdatePurpose = (data) => (dispatch, getState) => {
    commonAxios("update-purpose", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
            }
        }).catch((err) => {
            console.log(err.msg)
        })
}



export const onCreateRequirment = (data, setActive, setNewActive, req, setReq, setLoader) => (dispatch, getState) => {
    commonAxios("create-purpose-requirement", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setActive(false)
                setNewActive(false)
                setReq({
                    ...req,
                    id: res.data,
                    completed: 0
                })
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader(false)
        }).catch((err) => {
            setLoader(false)
            console.log(err.msg)
        })
}

export const onUpdateRequirment = (data, setActive, setNewActive, setLoader) => (dispatch, getState) => {
    commonAxios("update-purpose-requirement", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setActive(false)
                setNewActive(false)
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader(false)
        }).catch((err) => {
            setLoader(false)
            console.log(err.msg)
        })
}


export const onDeleteRequirment = (data, fetchReq, setSweet) => (dispatch, getState) => {
    commonAxios("delete-purpose-requirement", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                fetchReq()
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setSweet({
                enable: false,
                confirmButtonName: "Delete",
                loader: false
            })
        }).catch((err) => {
            console.log(err.msg)
            setSweet({
                enable: false,
                confirmButtonName: "Delete",
                loader: false
            })
        })
}

export const onContinueData = (ep, data, fetchData, handleClose, loader, setLoader) => (dispatch, getState) => {
    commonAxios(ep, data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                fetchData()
                handleClose()
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                continue: false
            })
        }).catch((err) => {
            console.log(err.msg)
            setLoader({
                ...loader,
                continue: false
            })

        })
}

export const onCheckCompelete = (data, fetchData, interval) => (dispatch, getState) => {
    commonAxios("fetch-status-content", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                if (+res.data[0].status === 2 || +res.data[0].status === 3) {
                    fetchData()
                    clearInterval(interval)
                }
            }
        }).catch((err) => {
            console.log(err.msg)
        })
}

export const onUploadDocs = (ep, data, onCompelete, loader, setLoader) => (dispatch, getState) => {
    commonAxios(ep, data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                onCompelete(res.data)
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                upload: false
            })
        }).catch((err) => {
            console.log(err.msg)
            dispatch(setAlert(err.msg, "danger"))
            setLoader({
                ...loader,
                upload: false
            })
        })
}

export const onReloadQuestion = (data, setQ, loader, setLoader) => (dispatch, getState) => {
    commonAxios("generate-auto-question", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setQ(res.data)
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                reload: false
            })
        }).catch((err) => {
            console.log(err.msg)
            dispatch(setAlert(err.msg, "danger"))
            setLoader({
                ...loader,
                reload: false
            })
        })
}

export const onUpdateContent = (data, fetchData, handleClose, loader, setLoader) => (dispatch, getState) => {
    commonAxios("update-content-resource", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                fetchData()
                handleClose()
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                update : false
            })
        }).catch((err) => {
            console.log(err)
            setLoader({
                ...loader,
                update : false
            })
        })
}


//ankit
export const onCreateFeed = (data, loader, setLoader, handleClose, fetchFeeds) => (dispatch, getState) => {
    commonAxios("create-feed", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                fetchFeeds();
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({ ...loader, save: false });
            handleClose();
        }).catch((err) => {
            console.log(err.msg)
            dispatch(setAlert(err.msg, "danger"))
            setLoader({ ...loader, save: false });
            handleClose();
        })
}
export const onFetchAllFeeds = (data, setFeeds, loader, setLoader) => (dispatch, getState) => {
    commonAxios("fetch-feed", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setFeeds(res.data)
            }
            setLoader({ ...loader, fetch: false });
        }).catch((err) => {
            console.log(err.msg)
            dispatch(setAlert(err.msg, "danger"))
            setLoader({ ...loader, fetch: false });
        })
}

// No Axios Here 

export const onChangePurpose = (name, value) => (dispatch) => {
    let data = { name, value }
    dispatch({ type: "ON_CHNAGE_PURPOSE", payload: data })
}

// export const onUpdateClonePurpose = (text, index) => (dispatch) => {
//     let data = { text, index }
//     dispatch({ type: "ON_CHNAGE_PURPOSE", payload: data })
// }