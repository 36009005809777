import React, { useState } from 'react'
import { PiArrowElbowDownRight } from "react-icons/pi"
import { FaArrowLeftLong, FaPlus } from 'react-icons/fa6'
import { IoFilterSharp } from 'react-icons/io5'
import { BiLoaderCircle, BiSearch } from 'react-icons/bi';
import arrow from "../../../../assets/images/arrow.png"
import note from '../../../../assets/images/note.png'
import { Modal } from 'react-bootstrap';
import { AiOutlineClose } from 'react-icons/ai';
const UserAudience = () => {
    const [addUser, setUser] = useState(false);
    const handleAdd = () => (
        setUser(true)
    )
    const handleAddClose = () => setUser(false);
    const [show, setShow] = useState()
    const handleClick = () => {
        setShow(!show)
    }
    const [inviteEmail, setInviteEmail] = useState('addUser');

    return (
        <>
            <div className="train_content_wrap" style={{ marginTop: "30px" }}>
                <div className="train_flex" >
                    <div className="train_content_head justify-content-center align-items-start flex-column">
                        <h2>Users </h2>
                        <p>See who's speaking to your clone</p>
                    </div>
                    <div className="d-flex mt-lg-0 mt-3 ms-2">
                        <button className='off-btn me-3'><span>Export <PiArrowElbowDownRight className="ms-1" /></span></button>
                        <button className='theme-btn' onClick={handleAdd}><span>Add users <FaPlus /></span></button>
                    </div>
                </div>
                <div className="train_content_search mt-5">
                    <div className="row">
                        <div className="col-xxl-2 col-xl-3 col-12">
                            <div style={{ position: "relative" }}>
                                <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="Search users" />
                                <span className="inpx-icon"><BiSearch style={{ color: "#a1a1aa", fontSize: "20px" }} /></span>
                            </div>
                        </div>
                        <div className="col-xl-2  col-6 mt-xl-0 mt-3 ms-3">
                            <div className="train_filter" style={{ position: "relative" }}>

                                <div className="theme-btn" onClick={handleClick} style={{ cursor: "pointer" }}>
                                    <span>Filter <IoFilterSharp className="ms-2" /></span>
                                </div>

                                {show ?
                                    <div style={{ position: "absolute" }}>

                                        <div className="accordion" id="accordionExample">
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="headingOne">
                                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="flase" aria-controls="collapseOne">
                                                        Memberships
                                                    </button>
                                                </h2>
                                                <div id="collapseOne" className="accordion-collapse collapse " aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                    <div className="accordion-body">
                                                        <p>You have not setup any memberships.
                                                            Create one now.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="headingThree">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                        Archived
                                                    </button>
                                                </h2>
                                                <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                    <div className="accordion-body">
                                                        <div className="form-check">
                                                            <label className="form-check-label" for="flexCheckChecked">
                                                                Show Archived Users
                                                            </label>
                                                            <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" />

                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <div className='p-3'>
                                                    <div className="d-flex justify-content-center ">
                                                        <button className='theme-btn me-3'><span>Reset</span></button>
                                                        <button className='theme-btn'><span>Apply</span></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    : ""

                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="train_table">
                    <div className="table-area mt-3">
                        <div className="table-responsive">
                            <table className="table widget-table">
                                <tr>
                                    <th>Content</th>
                                    <th>Uploaded</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                                <tr>
                                    <td>Mark</td>
                                    <td>Otto</td>
                                    <td>@mdo</td>
                                    <td>@mdo</td>
                                </tr>
                                <tr>
                                    <td>Jacob</td>
                                    <td>Thornton</td>
                                    <td>@fat</td>
                                    <td>@fat</td>

                                </tr>
                                <tr>
                                    <td>Larry the Bird</td>
                                    <td>@twitter</td>
                                    <td>@twitter</td>
                                    <td>@twitter</td>
                                </tr>


                            </table>
                            <div className="no_content">
                                <img className='mt-3' src={note} alt="" />
                                <b className='mt-2'>Add your first piece of content
                                </b>
                                <span style={{ color: "#a1a1aa" }}> Upload documents to start training your clone.</span>
                                <button className='theme-btn mt-3'><span>Add user <FaPlus /></span></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div >

            <Modal className='theme-modal video-modal feed' show={addUser} onHide={handleAddClose} centered>
                <Modal.Body className="p-0">
                    <div className='modalClose text-end' onClick={handleAddClose}><AiOutlineClose style={{ fontSize: "20px", marginBottom: "3px" }} /></div>
                    <div className="feed_warp email" style={{ marginTop: "10px", paddingBottom: "15px" }}>
                        <div className="container">
                            {inviteEmail === 'addUser' ?
                                < div className="row">
                                    <div className="col-12">
                                        <div className="feed_mod_head">
                                            <h2 className='mb-3'>Add Suggested Question</h2>
                                        </div>
                                        <div className="user_add">
                                            <div className="email_wrap">
                                                <div>
                                                    <h2>Invite by email</h2>
                                                    <p className='para_text '>Invite members to your audience</p>
                                                </div>
                                                <div>
                                                    <img src={arrow} alt="" style={{ cursor: "pointer", width: "22px" }} onClick={() => setInviteEmail("email")} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="user_add mt-3 mb-2">
                                            <div className="email_wrap">
                                                <div>
                                                    <h2>Import from CSV</h2>
                                                    <p className='para_text '>Upgrade to Prodigy to import your audience in bulk

                                                        Close</p>
                                                </div>
                                                <div>
                                                    <img src={arrow} alt="" style={{ cursor: "pointer", width: "22px" }} />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                :
                                <div className="row">
                                    <div className="col-12">
                                        <div onClick={() => setInviteEmail("addUser")} style={{ cursor: "pointer" }} >
                                            <span><FaArrowLeftLong style={{ fontSize: "20px", marginRight: '10px' }} />Return</span>
                                        </div>
                                        <div>
                                            <h2 className='mt-3 mb-2'>Invite by email</h2>
                                            <input
                                                className="common-inp alt"
                                                type="email"
                                                placeholder="Enter email address"
                                                name='email'
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="feed_btn text-end">
                                        <button className='theme-btn me-3 text-p4 mt-3' ><span> Save</span></button>
                                    </div>
                                </div>
                            }
                        </div>


                    </div >
                </Modal.Body>
            </Modal >
        </>
    )
}

export default UserAudience
