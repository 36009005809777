import React, { useState } from 'react';
import { BsStars } from "react-icons/bs";

import { FaUser, FaUserGroup } from "react-icons/fa6";
import { FaFileAlt } from "react-icons/fa";
import { Link, useLocation } from 'react-router-dom';
import CloneImage from './CommonSection/CloneImage';
import queryString from "query-string"

const CloneHome = ({ clone }) => {

    const location = useLocation()
    const { cId } = queryString.parse(location.search)

    return (
        <>
            <div className='studio-right' style={{ width: "100%" }}>
                <div className='pageTitle flex'>
                    <h2>Home</h2>
                    <CloneImage
                        image={clone.data.image}
                        radiness={20}
                    />
                </div>

                <div className='welcome-studio'>
                    <div className='welcome-studio-left'>
                        <div className='studio-left-icon'><BsStars /></div>
                        <p>Welcome to the redesigned Clone Studio <br /> <small>We rebuilt clone studio to make it more streamlined.</small></p>
                    </div>
                    <Link to={`/new-setting?cId=${cId}`} className='theme-btn' ><span>Check out the new settings</span></Link>
                </div>

                <div className='clone-summary'>
                    <h5 className='summary-title'>Summary</h5>
                    <div className='row'>
                        <div className='col-lg-4'>
                            <div className='clone-summary-single'>
                                <div className='clone-summary-title'>
                                    <h5>Users</h5>
                                    <div className='summary-period'>
                                        <select name="" id="">
                                            <option value="">Post 7 Days</option>
                                            <option value="">Total</option>
                                        </select>
                                    </div>
                                </div>
                                <div className='clone-summary-meta'>
                                    <h2>0</h2> <h5>Users</h5>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='clone-summary-single'>
                                <div className='clone-summary-title'>
                                    <h5>Messages</h5>
                                    <div className='summary-period'>
                                        <select name="" id="">
                                            <option value="">Post 7 Days</option>
                                            <option value="">Total</option>
                                        </select>
                                    </div>
                                </div>
                                <div className='clone-summary-meta'>
                                    <h2>0</h2> <h5>Messages</h5>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='clone-summary-single'>
                                <div className='clone-summary-title'>
                                    <h5>Revenue</h5>
                                    <div className='summary-period'>
                                        <select name="" id="">
                                            <option value="">Post 7 Days</option>
                                            <option value="">Total</option>
                                        </select>
                                    </div>
                                </div>
                                <div className='clone-summary-meta'>
                                    <h2><span>$</span>0</h2> <h5>USD</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className='clone-summary for-action'>
                    <h5 className='summary-title'>Quick Actions</h5>
                    <div className='row m-0'>
                        <div className='col-lg-4 p-0'>
                            <div className='clone-summary-single first'>
                                <div className='clone-summary-title'>
                                    <div className='studio-left-icon'><FaUser /></div>
                                </div>
                                <div className='clone-summary-meta'>
                                    <h6><strong>Update Clone Profile</strong> <br /> <small>Update your Clone's profile to share more information.</small></h6>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 p-0'>
                            <div className='clone-summary-single mid'>
                                <div className='clone-summary-title'>
                                    <div className='studio-left-icon'><FaUserGroup /></div>
                                </div>
                                <div className='clone-summary-meta'>
                                    <h6><strong>Explore Audience</strong> <br /> <small>Checkout the new Audience tab in Clone Studio.</small></h6>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 p-0'>
                            <div className='clone-summary-single last'>
                                <div className='clone-summary-title'>
                                    <div className='studio-left-icon'><FaFileAlt /></div>
                                </div>
                                <div className='clone-summary-meta'>
                                    <h6><strong>Upload Content</strong> <br /> <small>Train your clone by uploading documents, videos, websites, and more.</small></h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



        </>
    )

}

export default CloneHome;