import React, { useState } from 'react'
import { BsFillTrash3Fill } from 'react-icons/bs'
import { MdEdit, MdOutlineDataSaverOn } from 'react-icons/md'
import { themeColor } from '../../../Global/Global'
import { onDeletePurpose, onUpdateCustomePurpose } from '../../../Redux/Actions/CloneActions'
import { useDispatch } from 'react-redux'
import { RxCross2 } from "react-icons/rx";
import SweetAlert from '../../CommonComponents/SweetAlert'
import { IoIosCheckmarkCircleOutline } from 'react-icons/io'
const CustomePurposeRows = (props) => {
    const dispatch = useDispatch()
    const [activeEdit, setActiveEdit] = useState({
        active: false,
        text: ""
    })

    const [sweet, setSweet] = useState({
        enable: false,
        confirmButtonName: "Delete",
        loader: false
    })

    const handleUpdate = () => {
        let data = {
            id: props.curElem.id,
            cId : props.data.id,
            purpose: activeEdit.text
        }
        dispatch(onUpdateCustomePurpose(data, props.fetchCustomePurpose, setActiveEdit))
    }

    const handleEditClick = () => {
        setActiveEdit({
            ...activeEdit,
            active: true,
            text: props.curElem.purpose
        })
    }

    const handleEdit = (e) => {
        setActiveEdit({
            ...activeEdit,
            text: e.target.value
        })
    }



    const handleDelete = () => {
        setSweet({
            ...sweet,
            enable: true,
        })
    }

    const onCancelDelete = () => {
        setSweet({
            ...sweet,
            enable: false,
            confirmButtonName: "Delete",
            loader: false
        })
    }

    const performDelete = () => {
        let data = {
            id: props.curElem.id
        }
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting",
            loader: true
        })
        dispatch(onDeletePurpose(data, props.fetchCustomePurpose, setSweet))
    }


    return (
        <li className='d-flex justify-content-between'>
            <div style={{ width: "100%", marginRight: 10 }}>
                {activeEdit.active ?
                    <input
                        value={activeEdit.text}
                        onChange={handleEdit}
                        style={{ width: "100%", border: `1px solid ${themeColor}`, padding: 10, borderRadius: 15 }}
                    /> :
                    <p>{props.curElem.purpose}</p>
                }
                {/* <p>{props.curElem.purpose}</p> */}
                <label className='check-select'>
                    <input type="checkbox" checked={props.data.purpose.includes(props.curElem.purpose)} onChange={(e) => props.handleAdd(e, props.curElem.purpose)} />
                    <span className='check-select-mark'></span>
                </label>
            </div>
            <div style={{ fontSize: 19, width: "10%" }}>
                {activeEdit.active ?
                    <>
                        <IoIosCheckmarkCircleOutline
                            className='me-2' title='Update' cursor={"pointer"} onClick={handleUpdate}
                            color={themeColor}
                        />
                        <RxCross2 title='Cancel' cursor={"pointer"} onClick={() => setActiveEdit({
                            text: "",
                            active: false
                        })}
                            color={themeColor}
                        />
                    </>
                    :
                    <>
                        <MdEdit className='me-2' title='Edit' cursor={"pointer"} onClick={handleEditClick} color={themeColor} />
                        <BsFillTrash3Fill title='Delete' cursor={"pointer"} color={themeColor} onClick={handleDelete} />
                    </>
                }

            </div>

            <SweetAlert
                show={sweet.enable}
                message={"Are you sure you want to delete this purpose?"}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                alertLoader={sweet.loader}
                cancelButtonName={"Cancel"}
                handleClose={onCancelDelete}
                performDelete={performDelete}
            />
        </li>
    )
}

export default CustomePurposeRows