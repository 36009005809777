import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { BiCrop, BiTrashAlt, BiVolumeFull } from 'react-icons/bi'
import { BsDot, BsPauseFill, BsPlayFill, BsPlusLg, BsScissors } from 'react-icons/bs'
import { FiMusic } from 'react-icons/fi'
import { IoMdMusicalNote } from 'react-icons/io'
import { MdZoomOutMap } from 'react-icons/md'
import { RiArrowGoBackLine, RiArrowGoForwardLine } from 'react-icons/ri'
import { useSelector } from 'react-redux'
import { useDrag } from '@use-gesture/react'
import { FaPlay } from 'react-icons/fa'


let interval = false
const VideoTimeline = () => {
    const campaign = useSelector(state => state.video.campaign)
    const [sec, setSec] = useState([])
    const [neddelPos, setNeddelPos] = useState(0)
    const [isPlaying, setIsPlaying] = useState(false)
    const [devider, setDevider] = useState(0)
    const [tileArray, setTileArray] = useState([])
    useEffect(() => {
        let arr = []
        for (let i = 0; i < 50; i++) {
            arr[i] = {}
        }
        setSec(arr)
    }, [])

    const handlePlay = () => {
        setIsPlaying(true)
        interval = setInterval(() => {
            setNeddelPos(prev => prev + 1)
        }, 1)
    }

    const handlePause = () => {
        setIsPlaying(false)
        clearInterval(interval)
        interval = false
    }

    const onDragNeedle = useDrag((params) => {
        let movementVal = +params.movement[0].toFixed()
        let val = neddelPos + movementVal
        // setNeddelPos(val)
        let deviderVal = +devider.toFixed()
        if (neddelPos + movementVal - deviderVal >= 0) {
            if (1000 >= neddelPos + movementVal - deviderVal) {
                setNeddelPos(neddelPos + movementVal - deviderVal)
            }
        }
        if (params.active) {
            setDevider(movementVal)
        }
        else {
            setDevider(0)
        }

    })

    useEffect(() => {
        let arr = []
        if (campaign) {
            if (campaign.slides.length > 0) {
                campaign.slides.forEach((curElem, index) => {
                    let col = []
                    curElem.layers.forEach((layer, ind) => {
                        let dur = +curElem.duration * 100
                        layer = {
                            ...layer,
                            dur
                        }
                        col[ind] = layer
                    })
                    arr[index] = col
                })
            }
        }
        setTileArray(arr)
    }, [campaign])



    console.log(tileArray)
    return (
        <>
            <div className="control-bar px-1 mb-1" >
                <div className="control-bar-left">
                    <ul>
                        <li>
                            {isPlaying ?
                                <span
                                    className='px-4 py-1'
                                    style={{ background: "#00D0B0", borderRadius: 5 }}
                                    onClick={handlePause}
                                >
                                    <BsPauseFill size={20} />
                                </span>
                                :
                                <span
                                    className='px-4 py-1'
                                    style={{ background: "#00D0B0", borderRadius: 5 }}
                                    onClick={handlePlay}
                                >
                                    <BsPlayFill size={20} />
                                </span>
                            }

                        </li>
                        <li><BsScissors /><span>Split</span></li>
                        <li><BiTrashAlt /><span>Delete</span></li>
                    </ul>
                </div>
                <div className="control-bar-left">
                    <ul>

                        <li><MdZoomOutMap /><span>Crop</span></li>
                    </ul>
                </div>
            </div>
            <div className="timeline-wrap p-0">
                <div className='d-flex'>
                    {
                        sec.length > 0 ?
                            sec.map((curElem, index) => {
                                return (
                                    <div className='d-flex' style={{ fontSize: 12 }}>

                                        <span className='d-flex align-items-center justify-content-center'>
                                            <span style={{ width: 20 }}>{index}s</span>
                                            <BsDot style={{ width: 20, fontSize: 25 }} />
                                            <BsDot style={{ width: 20, fontSize: 25 }} />
                                            <BsDot style={{ width: 20, fontSize: 25 }} />
                                            <BsDot style={{ width: 20, fontSize: 25 }} />
                                        </span>
                                    </div>
                                )
                            }) : ""}

                </div>
                {/* <div className="timeline">
                    <ul>
                        <li>
                            <div className="timeline-single"><img alt="" src={require('../../../../assets/images/timeline-1.jpeg')} /></div>
                        </li>
                        <li>
                            <div className="timeline-single"><img alt="" src={require('../../../../assets/images/timeline-2.jpeg')} /></div>
                        </li>
                        <li>
                            <div className="timeline-single"><img alt="" src={require('../../../../assets/images/timeline-3.jpeg')} /></div>
                        </li>
                        <li>
                            <div className="timeline-single"><img alt="" src={require('../../../../assets/images/timeline-4.jpeg')} /></div>
                        </li>
                        <li>
                            <div className="add-timeline"><BsPlusLg /></div>
                        </li>
                    </ul>
                </div> */}
                <div className='' style={{ overflowY: "auto", height: 175 }}>

                    {tileArray.length > 0 ?
                        tileArray.map((curElem) => {
                            return (
                                curElem.length > 0 ?
                                    curElem.map((layer) => {
                                        return (
                                            <div style={{ background: "#fff" }}>
                                                <div className={`audio-timeline ${layer.type === "shape" ? "alt" : ""}`} style={{ width: layer.dur }}>
                                                    <div className="audio-timeline-in pt-2 text-dark" style={{fontSize : 12, fontWeight : "bold"}}>{layer.src.slice(0,40)}</div>
                                                    <span><IoMdMusicalNote /></span>
                                                </div>
                                            </div>
                                        )
                                    })
                                    : ""
                            )
                        })
                        : ""}
                    {/* {campaign.slides.length > 0 ?
                        campaign.slides.map((curElem, index) => {
                            return (
                                <div>
                                    <div className="audio-timeline" style={{ width: "80%" }}>
                                        <div className="audio-timeline-in"></div>
                                        <span><IoMdMusicalNote /></span>
                                    </div>
                                    <div className="audio-timeline alt" style={{ width: '30%', marginLeft: '35%' }}>
                                        <div className="audio-timeline-in"></div>
                                        <span><IoMdMusicalNote /></span>
                                    </div>
                                </div>
                            )
                        })
                        : ""} */}
                    <div className="audio-timeline " style={{ background: "#1d1b41", borderRadius: 0 }}>
                    </div>
                </div>
                <div
                    className="stick"
                    style={{ left: neddelPos, cursor: "col-resize", zIndex: 111, transition: 'none' }}
                    {...onDragNeedle()}
                >

                    <img alt="" src={require('../../../../assets/images/stick.png')} style={{ pointerEvents: "none" }} />
                </div>
            </div>
        </>
    )
}

export default VideoTimeline