import React from 'react'
import instaLogo from '../../../../../src/assets/images/SocialIcons/instagramLogo.png';
import facebook from '../../../../../src/assets/images/SocialIcons/facebook.png';
import twitter from '../../../../../src/assets/images/SocialIcons/twitter.png';
import website from '../../../../../src/assets/images/SocialIcons/wide.png';
import youtube from '../../../../../src/assets/images/SocialIcons/youtube.png';
import LinkedIn from '../../../../../src/assets/images/SocialIcons/linkedin.png';
import { IoAddOutline } from "react-icons/io5";
import { FaPlusCircle } from 'react-icons/fa';

const CloneSocial = () => {
    return (
        <div className='social_wrap'>
            <div className='row'>
                <div className=" col-xl-7 col-lg-12">
                    <h2>Clone Social</h2>
                    <p className='f-13 para_text2'>Display on your profile. Does not affect traning</p>
                    <div className="head d-flex justify-content-between mt-3">
                        <p className='f-13' style={{ color: "#52525b", fontWeight: "bold" }}>All Social</p>
                        <span><FaPlusCircle style={{ color: "#ff6a27", fontSize: "21px" }} /></span>
                    </div>
                </div>
                <div className="col-xl-7 col-lg-12 col-12">
                    <div className='band_wrapper mt-2'>
                        <div className="row align-items-center text-lg-start text-md-start text-center">
                            <div className="col-md-2 col-12 mb-lg-0 mb-md-0 mb-2">
                                <div className="band_img_wrap m-lg-0 m-md-0 m-auto">
                                    <img src={instaLogo} alt="insta Logo" className='img-fluid' />
                                </div>
                            </div>
                            <div className="col-md-7 col-12">
                                <div className="details">
                                    <h3 className="name">Instagram</h3>
                                    <p style={{ color: "#a1a1aa", fontSize: "13px", fontWeight: "500" }}>Add an account!</p>
                                </div>
                            </div>
                            <div className="col-md-2 col-12">
                                <button style={{ fontWeight: "500", fontSize: "13px" }} >Add <IoAddOutline className='ps-1 addIcon' /></button>
                            </div>
                        </div>
                    </div>
                    <div className='band_wrapper mt-4'>
                        <div className="row align-items-center text-lg-start text-md-start text-center">
                            <div className="col-md-2 col-12 mb-lg-0 mb-md-0 mb-2">
                                <div className="band_img_wrap m-lg-0 m-md-0 m-auto">
                                    <img src={facebook} alt="insta Logo" className='img-fluid' />
                                </div>
                            </div>
                            <div className="col-md-7 col-12">
                                <div className="details">
                                    <h3 className="name">Facebook</h3>
                                    <p style={{ color: "#a1a1aa", fontSize: "13px", fontWeight: "500" }}>Add an account!</p>

                                </div>
                            </div>
                            <div className="col-md-2 col-12">
                                <button style={{ fontWeight: "500", fontSize: "13px" }} >Add <IoAddOutline className='ps-1 addIcon' /></button>
                            </div>
                        </div>
                    </div>
                    <div className='band_wrapper mt-4'>
                        <div className="row align-items-center text-lg-start text-md-start text-center">
                            <div className="col-md-2 col-12 mb-lg-0 mb-md-0 mb-2">
                                <div className="band_img_wrap m-lg-0 m-md-0 m-auto">
                                    <img src={twitter} alt="insta Logo" className='img-fluid' />
                                </div>
                            </div>
                            <div className="col-md-7 col-12">
                                <div className="details">
                                    <h3 className="name">Twitter</h3>
                                    <p style={{ color: "#a1a1aa", fontSize: "13px", fontWeight: "500" }}>Add an account!</p>

                                </div>
                            </div>
                            <div className="col-md-2 col-12">
                                <button style={{ fontWeight: "500", fontSize: "13px" }}>Add <IoAddOutline className='ps-1 addIcon' /></button>
                            </div>
                        </div>
                    </div>
                    <div className='band_wrapper mt-4'>
                        <div className="row align-items-center text-lg-start text-md-start text-center">
                            <div className="col-md-2 col-12 mb-lg-0 mb-md-0 mb-2">
                                <div className="band_img_wrap m-lg-0 m-md-0 m-auto">
                                    <img src={youtube} alt="insta Logo" className='img-fluid' />
                                </div>
                            </div>
                            <div className="col-md-7 col-12">
                                <div className="details">
                                    <h3 className="name">Youtube</h3>
                                    <p style={{ color: "#a1a1aa", fontSize: "13px", fontWeight: "500" }}>Add an account!</p>

                                </div>
                            </div>
                            <div className="col-md-2 col-12">
                                <button style={{ fontWeight: "500", fontSize: "13px" }} >Add <IoAddOutline className='ps-1 addIcon' /></button>
                            </div>
                        </div>
                    </div>
                    <div className='band_wrapper mt-4'>
                        <div className="row align-items-center text-lg-start text-md-start text-center">
                            <div className="col-md-2 col-12 mb-lg-0 mb-md-0 mb-2">
                                <div className="band_img_wrap m-lg-0 m-md-0 m-auto">
                                    <img src={LinkedIn} alt="insta Logo" className='img-fluid' />
                                </div>
                            </div>
                            <div className="col-md-7 col-12">
                                <div className="details">
                                    <h3 className="name">LinkedIn</h3>
                                    <p style={{ color: "#a1a1aa", fontSize: "13px", fontWeight: "500" }}>Add an account!</p>

                                </div>
                            </div>
                            <div className="col-md-2 col-12">
                                <button style={{ fontWeight: "500", fontSize: "13px" }} >Add <IoAddOutline className='ps-1 addIcon' /></button>
                            </div>
                        </div>
                    </div>
                    <div className='band_wrapper mt-4'>
                        <div className="row align-items-center text-lg-start text-md-start text-center">
                            <div className="col-md-2 col-12 mb-lg-0 mb-md-0 mb-2">
                                <div className="band_img_wrap  m-lg-0 m-md-0 m-auto">
                                    <img src={website} alt="insta Logo" className='img-fluid' />
                                </div>
                            </div>
                            <div className="col-md-7 col-12">
                                <div className="details">
                                    <h3 className="name">Website</h3>
                                    <p style={{ color: "#a1a1aa", fontSize: "13px", fontWeight: "500" }}>Add an account!</p>

                                </div>
                            </div>
                            <div className="col-md-2 col-12">
                                <button style={{ fontWeight: "500", fontSize: "13px" }} >Add <IoAddOutline className='ps-1 addIcon' /></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CloneSocial
