import React from 'react'
import useAdd from '../../../../../src/assets/images/SocialIcons/useAdd.png';
import text from '../../../../../src/assets/images/SocialIcons/text_a.png';
import { FaPlus } from 'react-icons/fa';
import { MdWatchLater } from 'react-icons/md';

const Usage = () => {
    return (
        <>
            <div className="studio_setting ps-3">
                <div className="row">
                    <div className="col-12">
                        <div className="settings_head">
                            <h2>Usage</h2>
                        </div>
                        <div>
                            <div className="Usage_wrap mt-4">
                                <p className='f-18' style={{ color: "#18181b", fontWeight: "500" }}><i class="fa-solid fa-comment-dots me-2" style={{ color: "#ff6a27" }}></i>Messages</p>

                                <div className='mt-4'>
                                    <div class="progress" role="progressbar" aria-label="Basic example" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                        <div class="progress-bar w-25"></div>
                                    </div>
                                    <div className='d-flex justify-content-between align-items-center mt-1 '>
                                        <p className='f-13' style={{ color: "#71717a" }}><b className='text-dark' >3 / 10,000</b> messages used this billing period</p>
                                        <p className='f-13' style={{ color: "#71717a", fontWeight: "500" }}>Resets in 5 days</p>
                                    </div>

                                </div>

                            </div>
                            <div className='Usage_wrap_bottom'>
                                <div className='d-flex  justify-content-between align-items-center'>
                                    <div className='d-flex  align-items-center'>
                                        <span><img src={useAdd} alt="" /></span>
                                        <p className='f-13 ms-3' style={{ color: "#71717a", fontWeight: "500" }}><span className='text-dark'>Want more messages?</span><br />

                                            Add more messages to your plan</p>
                                    </div>
                                    <button className='theme-btn me-0 f-13'><span>Add <FaPlus style={{ fontSize: "13px" }} /></span></button>
                                </div>
                            </div>
                        </div>
                        {/* Calling Minutes */}
                        <div>

                            <div className="Usage_wrap mt-5">
                                <p className='f-18' style={{ color: "#18181b", fontWeight: "500" }}><MdWatchLater style={{ color: "#ff6a27" }} className='me-2' />Calling Minutes</p>

                                <div className='mt-4'>
                                    <div class="progress" role="progressbar" aria-label="Basic example" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                        <div class="progress-bar w-25"></div>
                                    </div>
                                    <div className='d-flex justify-content-between align-items-center mt-1 '>
                                        <p className='f-13' style={{ color: "#71717a" }}><b className='text-dark' >3 / 10,000</b> messages used this billing period</p>
                                        <p className='f-13' style={{ color: "#71717a", fontWeight: "500" }}>Resets in 5 days</p>
                                    </div>

                                </div>

                            </div>
                            <div className='Usage_wrap_bottom'>
                                <div className='d-flex  justify-content-between align-items-center'>
                                    <div className='d-flex  align-items-center'>
                                        <span><img src={useAdd} alt="" /></span>
                                        <p className='f-13 ms-3' style={{ color: "#71717a", fontWeight: "500" }}><span className='text-dark'>Want more messages?</span><br />

                                            Add more messages to your plan</p>
                                    </div>
                                    <button className='theme-btn me-0 f-13'><span>Add <FaPlus style={{ fontSize: "13px" }} /></span></button>
                                </div>
                            </div>
                        </div>
                        {/* end div */}
                        <div className='mt-2 usage_mid' >
                            <div style={{ maxWidth: "575px" }}>
                                <h2 className='f-18 mt-5'>Enable overages</h2>
                                <div className='d-flex justify-content-between align-items-center w-100'>

                                    <p className='f-13' style={{ color: "#71717a", fontWeight: "500", width: "90%" }}>
                                        Allow clone usage to exceed the above quotas. We will send an invoice automatically at the end of the period with the overage charges.</p>
                                    <div className="opt-select">
                                        <label className="switch">
                                            <input
                                                type="checkbox"
                                            // checked={selectedSlide.faceNarration.enable}
                                            // onChange={handleChangeEnable}
                                            />
                                            <span className="slider round" style={{ background: "#e4e4e7" }}></span>
                                        </label>
                                    </div>
                                </div>

                            </div>
                        </div>
                        {/* Training Words */}
                        <div>
                            <div className="Usage_wrap mt-5">
                                <p className='f-18' style={{ color: "#18181b", fontWeight: "500" }}> <img src={text} alt className='me-2' /> Training Words</p>

                                <div className='mt-4'>
                                    <div class="progress" role="progressbar" aria-label="Basic example" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                        <div class="progress-bar w-25"></div>
                                    </div>
                                    <div className='d-flex justify-content-between align-items-center mt-1 '>
                                        <p className='f-13' style={{ color: "#71717a" }}><b className='text-dark' >3 / 10,000</b> messages used this billing period</p>
                                        <p className='f-13' style={{ color: "#71717a", fontWeight: "500" }}>Resets in 5 days</p>
                                    </div>

                                </div>

                            </div>
                            <div className='Usage_wrap_bottom'>
                                <div className='d-flex  justify-content-between align-items-center'>
                                    <div className='d-flex  align-items-center'>
                                        <span><img src={useAdd} alt="" /></span>
                                        <p className='f-13 ms-3' style={{ color: "#71717a", fontWeight: "500" }}><span className='text-dark'>Want more messages?</span><br />

                                            Add more messages to your plan</p>
                                    </div>
                                    <button className='theme-btn me-0 f-13'><span>Add <FaPlus style={{ fontSize: "13px" }} /></span></button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div >
        </>
    )
}

export default Usage
