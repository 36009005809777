import React, { useEffect, useState } from 'react'
import CloneHome from './CloneHome'
import { Nav, Tab, Button } from 'react-bootstrap';
import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import TitleBar from '../../CommonComponents/TitleBar';

import { FiHome, FiUsers } from "react-icons/fi";
import { MdOutlineFilterCenterFocus } from "react-icons/md";
import { IoPieChartOutline } from "react-icons/io5";
import { BiMoneyWithdraw } from "react-icons/bi";
import CloneTrain from './CloneTrain/CloneTrain';
import Audience from './Audience/Audience';
import Analytics from './Analytics/Analytics';
import MonetizationMain from './Monetization/MonetizationMain';

import queryString from "query-string"
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { onFetchCloneRedux, onFetchCloneStudio } from '../../../Redux/Actions/CloneActions';
import { useSelector } from 'react-redux';


const CloneNav = () => {

    const dispatch = useDispatch()
    const location = useLocation()
    const { cId } = queryString.parse(location.search)
    const clone = useSelector(state => state.clone)

    const [loader, setLoader] = useState({
        fetch: true
    })


    const fetchClone = () => {
        let data = {
            cId
        }
        dispatch(onFetchCloneRedux(data, loader, setLoader))
    }

    const fetchStudio = () => {
        let data = {
            cId
        }
        dispatch(onFetchCloneStudio(data, loader, setLoader))
    }

    useEffect(() => {
        if (cId) {
            fetchClone()
            fetchStudio()
        }
    }, [cId])

    return (
        loader.fetch ?
            <div className="loader-sec">
                <div className="loader" />
            </div>
            :
            <>
                <Header />
                <TitleBar title="Training" />
                <div className="site-wrap" style={{ overflow: "auto" }}>
                    <div className="container-fluid">
                        <div className='studio-wrap'>
                            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                <div className="row">
                                    <div className="col-xl-2 col-md-3 col-12">
                                        <div className='studio-nav'>
                                            <Nav variant="pills" className="flex-column">
                                                <Nav.Item>
                                                    <Nav.Link eventKey="first" ><FiHome /> Home</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="second"><MdOutlineFilterCenterFocus /> Train</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="third"><FiUsers /> Audience</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="fourth"><IoPieChartOutline /> Analytics</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="fifth"><BiMoneyWithdraw /> Monetization</Nav.Link>
                                                </Nav.Item>
                                            </Nav>

                                        </div>

                                    </div>
                                    <div className="col-xl-10 col-md-9 mt-md-0 mt-5 col-12">
                                        <Tab.Content>
                                            <Tab.Pane eventKey="first">
                                                <CloneHome
                                                    clone={clone}
                                                />

                                            </Tab.Pane>
                                            <Tab.Pane eventKey="second">
                                                <CloneTrain
                                                    clone={clone}
                                                />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="third">
                                                <Audience />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="fourth">
                                                <Analytics />
                                            </Tab.Pane>

                                            <Tab.Pane eventKey="fifth">
                                                <MonetizationMain />
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </div>
                                </div>
                            </Tab.Container >
                        </div >
                    </div >
                </div >

                <Footer />
            </>
    )
}

export default CloneNav
