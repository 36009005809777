import React, { useEffect, useState } from 'react'
import { MdOutlinePlayCircle, MdOutlinePauseCircle, MdOutlineStopCircle } from "react-icons/md";
import { onFetchCloneLang, onFetchVoice, onGenerateCloneTts, onSaveVoice } from '../../../../Redux/Actions/CloneActions';
import { useDispatch } from 'react-redux';
import Swal from "sweetalert2"
import { themeColor } from '../../../../Global/Global';
import VoiceTrainModal from './SubComponent/VoiceTrainModal';
import { fetchData } from '../../../../Redux/Actions/CommonActions';
import VoiceRows from './SubComponent/VoiceRows';
import queryString from "query-string"
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

let audioTts = false, mainAudio = false
const VoiceTrain = () => {
    const dispatch = useDispatch()
    const location = useLocation()

    const { cId } = queryString.parse(location.search)
    const studioData = useSelector(state => state.clone.studioData)
    const [play, setPlay] = useState(false)
    const [playTts, setPlayTts] = useState(false)
    const [ttsStatus, setTtsStatus] = useState(false)
    const [pollyLanguages, setPollyLanguages] = useState([]);
    const [pollyVoices, setPollyVoices] = useState([]);
    const [uploadVoice, setUploadVoice] = useState([]);
    const [recordedVoice, setRecordedVoice] = useState([]);

    const [voices, setVoices] = useState([])

    const [ttsData, setTtsData] = useState({
        type: "tts",
        src: "",
        duration: "",
        meta: {
            text: "",
            language_id: "",
            langId: 13,
            voiceId: "",
            voice_id: "",
            engine: ""
        }

    });

    const [loader, setLoader] = useState({
        generate: false,
        fetch: true,
        save: false
    })

    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);


    const handleToggle = (type) => {
        setTtsData({
            ...ttsData,
            type: type
        })
    }

    const handlePlay = () => {
        mainAudio = new Audio(ttsData.src)
        mainAudio.play()
        mainAudio.onended = () => {
            setPlay(false)
        }
        setPlay(true)
    }

    const handlePause = () => {
        if (mainAudio) {
            mainAudio.pause()
        }
        setPlay(false)
    }

    const onChangeTtsData = (e, innerType) => {
        const { name, value } = e.target
        if (innerType) {
            setTtsData({
                ...ttsData,
                meta: {
                    ...ttsData.meta,
                    [name]: value
                }
            })
        } else {
            setTtsData({
                ...ttsData,
                [name]: value
            })
        }

        setTtsStatus(false)
        if (audioTts) {
            audioTts.pause()
            setPlayTts(false)
        }
    }

    const generateTts = () => {
        if (ttsData.meta.text !== "" && ttsData.meta.langId !== "" && ttsData.meta.voiceId !== "") {

            let obj = { ...ttsData.meta }

            let langData = pollyLanguages.find(({ id }) => +id === +obj.langId)
            if (langData) {
                obj.language_id = langData.code
            }
            let artist = pollyVoices.find(({ id }) => +id === +obj.voiceId)

            if (artist) {
                setLoader({
                    ...loader,
                    generate: true
                });
                if (artist.type === "Neural") {
                    obj.engine = artist.type.replace("Neural", "neural")
                }
                else {
                    obj.engine = artist.type.replace("Standard", "standard")
                }

                obj.voice_id = artist.voice_id
                dispatch(onGenerateCloneTts(obj, ttsData, setTtsData, loader, setLoader, setTtsStatus))
            }

        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please, add text, language & voice in TTS!',
                confirmButtonColor: themeColor
            })
        }
    }

    const onPlayTts = (type) => {
        if (type === "play") {
            audioTts = new Audio(ttsData.src)
            audioTts.play()
            audioTts.onended = () => {
                setPlayTts(false)
            }
            setPlayTts(true)
        } else {
            if (audioTts) {
                audioTts.pause()
            }
            setPlayTts(false)
        }
    }

    const handleUse = (ttsDataVal) => {
        let data = { voice: { ...ttsDataVal } }
        data.id = cId
        setLoader({
            ...loader,
            save: true
        })
        dispatch(onSaveVoice(data, loader, setLoader))
    }


    const fetchPollyLanguages = () => {
        dispatch(onFetchCloneLang(setPollyLanguages))
    }
    const fetchPollyVoices = (value) => {
        dispatch(onFetchVoice(value, setPollyVoices))
    }

    const fetchUploadVoice = () => {
        let data = { type: "voiceover" }
        dispatch(fetchData("fetch-user-uploaded-file", data, setUploadVoice, loader, setLoader))
        data.type = "recording"
        dispatch(fetchData("fetch-user-uploaded-file", data, setRecordedVoice, loader, setLoader))
    }

    useEffect(() => {
        let arr = [...uploadVoice, ...recordedVoice]
        if (arr.length > 0) {
            setVoices(
                arr.filter((curElem) => {
                    return {
                        id: curElem.id,
                        name: curElem.name,
                        url: curElem.url,
                        type: curElem.type ? curElem.type : "Voiceover"
                    }
                })
            )
        } else {
            setVoices(arr)
        }
    }, [uploadVoice, recordedVoice])

    useEffect(() => {
        if (ttsData.meta.langId) {
            fetchPollyVoices(ttsData.meta.langId)
        } else {
            setPollyVoices([])
            fetchPollyVoices(13)
        }
    }, [ttsData.meta.langId])


    useEffect(() => {
        if (studioData.voice) {
            setTtsData({
                ...ttsData,
                ...studioData.voice
            })
        }
    }, [studioData])


    useEffect(() => {
        fetchPollyLanguages()
        fetchUploadVoice()
    }, [])

    return (
        <>
            <div className="voice_content_wrap" style={{ marginTop: "30px" }}>
                <div className="container-fluid" >
                    <div className="row" style={{ padding: "0 20px" }}>
                        <div className="col-12 d-flex align-items-center justify-content-between">
                            <div className="Train_content_head ">
                                <h2>Voice </h2>
                                <p className='mt-1 para_text mb-0' >This field affects all aspect of your clone, shaping training and informing its responses.</p>
                            </div>
                            <div>
                                {!play ?
                                    <MdOutlinePlayCircle size={35} onClick={handlePlay} cursor={"pointer"} title='Play' /> :
                                    <MdOutlinePauseCircle size={35} onClick={handlePause} cursor={"pointer"} title='Pause' />
                                }
                            </div>
                        </div>
                    </div>
                    <hr className='' style={{ opacity: "1", color: "#a1a1aa45" }} />
                    <div className="row" style={{ padding: "0 20px" }}>
                        <div className="col-12">

                            <div className='activity text-end ms-3'>
                                <div className="btn-group" role="group" aria-label="Basic radio toggle button group">
                                    <input type="radio" className="btn-check" name="btnradio" id="btnradio1" autoComplete="off" />
                                    <label className={`btn btn-outline-primary ${ttsData.type === "tts" ? "active" : ""}`} htmlFor="btnradio1" onClick={() => handleToggle("tts")}>TTS  </label>

                                    <input type="radio" className="btn-check" name="btnradio" id="btnradio3" autoComplete="off" />
                                    <label className={`btn btn-outline-primary ${ttsData.type === "voiceover" ? "active" : ""}`} htmlFor="btnradio3" onClick={() => handleToggle("voiceover")}>Voiceover</label>
                                </div>
                            </div>


                            {ttsData.type === "tts" ?

                                <div className="col-lg-9 col-12">
                                    <div className="audio-scroll-2 mt-4">
                                        <div className="font-block voice">
                                            <div className="row sm pt-2 mt-2">

                                                <div className="col-5">
                                                    <h6>Langauge</h6>
                                                    <select
                                                        className="inp-small mt-2"
                                                        name="langId"
                                                        onChange={(e) => onChangeTtsData(e, "meta")}
                                                        value={ttsData.meta.langId}
                                                    >
                                                        <option value={""}>Language</option>
                                                        {pollyLanguages.length > 0 ?
                                                            pollyLanguages.map((curElem, index) => {
                                                                return (
                                                                    <option key={index} value={curElem.id}>{curElem.name}</option>
                                                                )
                                                            })
                                                            : ""}
                                                    </select>
                                                </div>
                                                <div className="col-5">
                                                    <h6>Voice</h6>
                                                    <select
                                                        className="inp-small mt-2"
                                                        name='voiceId'
                                                        value={ttsData.meta.voiceId}
                                                        onChange={(e) => onChangeTtsData(e, "meta")}
                                                    >
                                                        <option value={""}>Select Voice</option>
                                                        {
                                                            pollyVoices.length > 0 ?
                                                                pollyVoices.map((item, index) => {
                                                                    return (
                                                                        <option key={index} value={item.id}>{item.voice_id} ({item.gender}), {item.type}</option>
                                                                    )
                                                                })
                                                                : ''}
                                                    </select>
                                                </div>
                                            </div>

                                            <h6 className="pt-4">Script</h6>
                                            <textarea
                                                className="inp-small mt-2"
                                                onChange={(e) => onChangeTtsData(e, "meta")}
                                                name='text'
                                                value={ttsData.meta.text}
                                            />

                                            <div className="col-xxl-12 mt-4">
                                                <div className="d-flex justify-content-between ms-2 align-items-center ">
                                                    {ttsStatus ?
                                                        !playTts ?
                                                            <button className='theme-btn me-3' onClick={() => onPlayTts("play")}><span>
                                                                <MdOutlinePlayCircle />  Play
                                                            </span>
                                                            </button>
                                                            :
                                                            <button className='theme-btn me-3' onClick={() => onPlayTts("pause")}><span>
                                                                <MdOutlineStopCircle />  Stop
                                                            </span>
                                                            </button>
                                                        :
                                                        <button className='theme-btn me-3' onClick={generateTts}><span>
                                                            {loader.generate ? <>Generating <i className='fa fa-spin fa-spinner' /></> : "Generate"}
                                                        </span></button>
                                                    }
                                                    {ttsStatus ?
                                                        <button className='theme-btn' onClick={() => handleUse(ttsData)}><span>{loader.save ? <>Using <i className='fa fa-spin fa-spinner' /></> : "Use"} </span></button>
                                                        : null}
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                :

                                <>
                                    <div className="Train_content_head row align-items-center mt-4">
                                        <div className='col-xl-6 col-12 '>
                                            <h4>Uploaded voice samples </h4>
                                        </div>
                                        <div className='col-xl-6 col-12 text-md-end text-center '>
                                            {/* <button className='off-btn me-3 text-p4 ' onClick={handleShow}><span>Open Pronunciation Table</span></button> */}
                                            <button className='theme-btn text-p4 mt-lg-0 mt-2' onClick={handleShow2}><span>Upload voice sample</span></button>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="train_table mt-4">
                                                    <div className="table-area ">
                                                        <div className="table-responsive">
                                                            <table className="table widget-table">
                                                                <tr>
                                                                    <th></th>
                                                                    <th>Name</th>
                                                                    <th>Type</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                                {voices.length > 0 ?
                                                                    voices.map((curElem, index) => {
                                                                        return (
                                                                            <React.Fragment key={index}>
                                                                                <VoiceRows
                                                                                    curElem={curElem}
                                                                                    index={index}
                                                                                    fetchVoice={fetchUploadVoice}
                                                                                    handleUse={handleUse}
                                                                                    setTtsData={setTtsData}
                                                                                    ttsData={ttsData}
                                                                                />
                                                                            </React.Fragment>
                                                                        )
                                                                    })
                                                                    :
                                                                    <tr>
                                                                        <td colSpan={4} className='text-center' style={{ borderBottom: "none" }}>
                                                                            {loader.fetch ?
                                                                                <i className="fa fa-spin fa-spinner fetch-loader" /> : "No voice uploaded yet!"}
                                                                        </td>
                                                                    </tr>

                                                                }
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='Add_upload_modal'>
                                        <VoiceTrainModal
                                            show={show2}
                                            handleClose={handleClose2}
                                            fetchUploadVoice={fetchUploadVoice}
                                        />
                                    </div>
                                </>


                            }
                        </div>
                    </div>
                </div>
            </div >

        </>
    )
}

export default VoiceTrain
