import React, { useEffect, useState } from 'react'
import { BsChatDotsFill } from 'react-icons/bs'
import { MdEdit } from 'react-icons/md'
import { FaTrash } from 'react-icons/fa6'
import { Link } from 'react-router-dom'
import SweetAlert from '../../CommonComponents/SweetAlert'
import { onDeleteCampaign } from '../../../Redux/Actions/CommonActions'
import { useDispatch } from 'react-redux'

const CloneCards = ({ curElem, cloneData, setCloneData }) => {
    const dispatch = useDispatch()
    let arr = Array(30).fill("")
    const [activeBar, setActiveBar] = useState(-1)

    const [sweet, setSweet] = useState({
        enable: false,
        confirmButtonName: "Delete",
        loader: false
    })


    const onDelete = () => {
        setSweet({
            ...sweet,
            enable: true,
        })
    }

    const onCancelDelete = () => {
        setSweet({
            ...sweet,
            enable: false,
            confirmButtonName: "Delete",
            loader: false
        })
    }

    const performDelete = () => {
        let data = {
            id: curElem.id
        }
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting",
            loader: true
        })
        dispatch(onDeleteCampaign("delete-clone", data, cloneData, setCloneData, setSweet))
    }





    useEffect(() => {
        if (curElem) {
            let total = (30 * curElem.percent) / 100
            setActiveBar(total)
        }
    }, [curElem])

    return (
        <div className='col-lg-4 mt-4'>
            <div className='clone-card'>
                <div className='clone-card-top'>
                    <span>Clone ID : <strong>{curElem.id}</strong></span>

                </div>
                <div className='clone-card-profile'>
                    <div className='clone-card-profile-img'><img src={curElem.image} alt="" /></div>
                    <div className='clone-card-profile-txt'>
                        <h5>{curElem.name}</h5>
                        <p>{curElem.headLine}</p>
                    </div>
                </div>
                <div className='clone-radiness'>
                    <h6>Clone Radiness</h6>
                    <div className='radiness-graph'>
                        <div className='radiness-graph-left'>
                            {arr.length > 0 ?
                                arr.map((bar, index) => {
                                    return (
                                        <span className={index < +activeBar ? "active" : ""} key={index}></span>
                                    )
                                })

                                : ""}

                        </div>
                        <div className='radiness-stat'>19%</div>
                    </div>
                </div>
                <div className='clone-meta'>
                    <div className='clone-meta-left'>
                        {/* <p><small>DATE OF BIRTH</small> <br /> <strong>FEB 13, 2024</strong></p> */}
                        <p><small>DATE OF BIRTH</small> <br /> <strong>{curElem.created.slice(0, 10)}</strong></p>
                    </div>
                    <div className='clone-meta-right'>
                        <Link to={`/clone-nav?cId=${curElem.id}`} title='Edit'><MdEdit /></Link>
                        <span title=''><BsChatDotsFill /></span>
                        <span title='Delete' onClick={onDelete}><FaTrash /></span>
                    </div>
                </div>
            </div>

            <SweetAlert
                show={sweet.enable}
                message={"Are you sure you want to delete this clone?"}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                alertLoader={sweet.loader}
                cancelButtonName={"Cancel"}
                handleClose={onCancelDelete}
                performDelete={performDelete}
            />
        </div>
    )
}

export default CloneCards