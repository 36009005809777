import React from 'react'
import { FaPlus } from 'react-icons/fa';
import { FaCommentDots } from "react-icons/fa6";

const ToneTrain = () => {
    return (
        <>
            <div className="voice_content_wrap" style={{ marginTop: "30px" }}>
                <div className="container-fluid" >
                    <div className="row" style={{ padding: "0 20px" }}>
                        <div className="col-12">
                            <div className="train_content_head row ">
                                <div className='col-xxl-8 col-lg-7 col-12'>
                                    <h2>Tone</h2>
                                    <p className='mt-1 para_text' >Demeanor and diction of your clone. A simplified summary of your tone will be displayed here after tone extraction.</p>

                                </div>
                                <div className='col-xxl-4 col-lg-5 col-12 text-xxl-end text-center mt-xxl-0 mt-xl-3 mt-3'>
                                    <button className='theme-btn me-3 text-p4 ' ><span>Extract Tone from Content</span></button>

                                </div>
                            </div>
                            <hr className='' style={{ opacity: "1", color: "#a1a1aa45" }} />
                        </div>
                        <div className="train_table  mt-4">
                            <div className="table-area">
                                <div className="table-responsive">
                                    <table className="table widget-table">
                                        <tr>
                                            <th>Content</th>
                                            <th>Uploaded</th>
                                            <th>Status</th>
                                            <th>Action</th>
                                        </tr>
                                        <tr>
                                            <td>Mark</td>
                                            <td>Otto</td>
                                            <td>@mdo</td>
                                            <td>@mdo</td>
                                        </tr>
                                        <tr>
                                            <td>Jacob</td>
                                            <td>Thornton</td>
                                            <td>@fat</td>
                                            <td>@fat</td>

                                        </tr>
                                        <tr>
                                            <td>Larry the Bird</td>
                                            <td>@twitter</td>
                                            <td>@twitter</td>
                                            <td>@twitter</td>
                                        </tr>


                                    </table>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ToneTrain

