import produce from "immer"
const initialState = {
    data: {
        cId: "",
        sId: "",
        cloneProfile: {
            name: "hello",
            profile: "",
            handle: ""
        },
        cloneBio: {
            headLine: "",
            description: "",
            topics: "",
            organizations: "",
            customTopicArray: [],
            selectedTopic: []
        }
    }
}

export const CloneSettingReducer = (state = initialState, action) => {
    switch (action.type) {

        case "CHANGE_CLONE_SETTING_PROFILE":
            return produce(state, (draft) => {
                draft.data.cloneProfile[action.payload.name] = action.payload.value
            })
        case "CHANGE_CLONE_SETTING_BIO":
            return produce(state, (draft) => {
                draft.data.cloneBio[action.payload.name] = action.payload.value
            })

        default:
            return state
    }
}
