import React, { useEffect, useState } from 'react'
import { FaArrowCircleDown } from 'react-icons/fa'
import { FaPlus } from 'react-icons/fa6'
import { BiSearch } from 'react-icons/bi';
import note from '../../../../../assets/images/note.png'
import AddContentModal from './AddContentModal';
import ContentFilter from './ContentFilter';
import ContentRows from './ContentRows';
import { useLocation } from 'react-router-dom';
import queryString from "query-string"
import { useDispatch } from 'react-redux';
import { fetchData } from '../../../../../Redux/Actions/CommonActions';
import { useSelector } from 'react-redux';
import { useImmer } from 'use-immer';


const Content = () => {
    const location = useLocation()
    const dispatch = useDispatch()
    const { cId } = queryString.parse(location.search)
    const studioData = useSelector(state => state.clone.studioData)
    const [q, setQ] = useState("")
    const [filterType, setFilterType] = useImmer([])
    const [statusType, setStatusType] = useImmer([])
    const [loader, setLoader] = useState({
        fetch: true
    })
    const [contentNav, setContentNav] = useState([])
    const [contentData, setContentData] = useState([])
    const [show, setShow] = useState(false)
    const [show2, setShow2] = useState(false);

    const handleToggle = () => {
        setShow(!show)
    }

    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);


    const fetchContent = () => {
        let data = {
            cId: studioData.cId,
            sId: studioData.id
        }
        dispatch(fetchData("fetch-content", data, setContentData, loader, setLoader))
    }
    const fetchContentNavs = () => {
        let data = {}
        dispatch(fetchData("fetch-content-source", data, setContentNav))
    }


    useEffect(() => {
        if (studioData) {
            fetchContent()
        }
    }, [studioData.cId])


    useEffect(() => {
        fetchContentNavs()
    }, [])

    return (
        <>
            <div className="train_content_wrap" style={{ marginTop: "30px" }}>
                <div className="train_flex" >
                    <div className="train_content_head main ">
                        <h2>Content </h2>
                        <p style={{ marginLeft: "6px" }}> <span style={{ marginRight: "4px", fontWeight: "100" }}>|</span>0 uploads • 0 words</p>
                    </div>
                    <div className="d-flex mt-lg-0 mt-3 ms-2">
                        <button className='off-btn me-3'><span><FaArrowCircleDown className='me-1' style={{ color: "#71717a" }} />Export</span></button>
                        <button className='theme-btn' onClick={handleShow2}><span>Add Content <FaPlus /></span></button>
                    </div>
                </div>
                <div className='Add_pronunciation_modal'>
                    <AddContentModal
                        show={show2}
                        handleClose={handleClose2}
                        fetchContent={fetchContent}
                        contentNav={contentNav}
                    />
                </div>
                <div className="train_content_search mt-5">
                    <div className="row align-items-center">
                        <div className="col-xxl-3 col-12">
                            <div className="train_content_search mt-3 mb-4">

                                <div style={{ position: "relative" }}>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search by source name"
                                        value={q}
                                        onChange={(e) => setQ(e.target.value)}
                                    />
                                    <span className="inpx-icon"><BiSearch style={{ color: "#a1a1aa", fontSize: "20px" }} /></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2  col-6 mt-xl-0 mt-2 ms-3">
                            <ContentFilter
                                handleToggle={handleToggle}
                                show={show}
                                contentNav={contentNav}
                                setFilterType={setFilterType}
                                filterType={filterType}
                                setStatusType={setStatusType}
                                statusType={statusType}
                            />
                        </div>
                    </div>
                </div>
                <div className="train_table">
                    <div className="table-area mt-3">
                        <div className="table-responsive">
                            <table className="table widget-table text-center">
                                <tr>
                                    <th></th>
                                    <th>Type</th>
                                    <th>View</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                                {contentData.length > 0 ?
                                    contentData.filter((curE) => {
                                        return curE.source.toLowerCase().includes(q.toLowerCase()) || q === ""
                                    }).filter((curElem) => {
                                        return filterType.includes(curElem.source) || filterType.length === 0
                                    }).filter((curElem) => {
                                        return statusType.includes(curElem.status) || statusType.length === 0
                                    }).map((curElem, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                <ContentRows
                                                    curElem={curElem}
                                                    index={index}
                                                    fetchData={fetchContent}
                                                    contentData={contentData}
                                                    setContentData={setContentData}
                                                />
                                            </React.Fragment>
                                        )
                                    })
                                    :
                                    <tr>
                                        <td colSpan={5} className='text-center py-5' >
                                            {loader.fetch ? <><i className='fa fa-spin fa-spinner fetch-loader' /></> : "No content created yet!"}
                                        </td>
                                    </tr>

                                }
                            </table>
                            <div className="no_content">
                                <img className='mt-3' src={note} alt="" />
                                <b className='mt-2'>Add your first piece of content
                                </b>
                                <span style={{ color: "#a1a1aa" }}> Upload documents to start training your clone.</span>
                                <button className='theme-btn mt-3'><span>Add Content <FaPlus /></span></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default Content
