import React, { useEffect, useState } from 'react'

const CloneImage = (props) => {
    let arr = Array(30).fill("")
    const [activeBar, setActiveBar] = useState(-1)

    useEffect(() => {
        if (props.radiness) {
            let total = (30 * props.radiness) / 100
            setActiveBar(total)
        }
    }, [props.radiness])
    return (
        <div className='clone-radiness alt mt-0'>
            <div className='radiness-graph mt-0'>
                <div className='clone-card-profile-img alt'><img src={props.image} alt="" /></div>
                <div className='radiness-graph-left'>
                    {arr.length > 0 ?
                        arr.map((bar, index) => {
                            return (
                                <span className={index < +activeBar ? "active" : ""} key={index}></span>
                            )
                        })

                        : ""}
                    
                </div>
                <div className='radiness-stat'>{props.radiness}%</div>
            </div>
        </div>
    )
}

export default CloneImage