import React, { useState } from 'react'
import { FaPlus } from 'react-icons/fa6'
import { IoFilterSharp } from 'react-icons/io5'
import note from '../../../../assets/images/note.png'
import { BiSearch } from 'react-icons/bi'
const MassMessagesAudience = () => {
    const [show, setShow] = useState()
    const handleClick = () => {
        setShow(!show)
    }
    return (
        <>
            <div className="train_content_wrap" style={{ marginTop: "30px" }}>
                <div className="train_flex" >
                    <div className="train_content_head justify-content-center align-items-start flex-column">
                        <h2>Mass messages </h2>
                    </div>
                    <div className="d-flex mt-lg-0 mt-3 ms-2">
                        <button className='theme-btn'><span>New mass messages <FaPlus /></span></button>
                    </div>
                </div>
                <hr className='mt-4' style={{ opacity: "1", color: "#a1a1aa45" }} />
                <div className="train_content_search mt-4 mb-4">
                    <div className="row">

                        <div className="col-xxl-2 col-xl-3 col-12">
                            <div style={{ position: "relative" }}>
                                <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="Search content" />
                                <span className="inpx-icon"><BiSearch style={{ color: "#a1a1aa", fontSize: "20px" }} /></span>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="train_table">
                    <div className="table-area mt-3">
                        <div className="table-responsive">
                            <table className="table widget-table">
                                <tr>
                                    <th>Content</th>
                                    <th>Uploaded</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                                <tr>
                                    <td>Mark</td>
                                    <td>Otto</td>
                                    <td>@mdo</td>
                                    <td>@mdo</td>
                                </tr>
                                <tr>
                                    <td>Jacob</td>
                                    <td>Thornton</td>
                                    <td>@fat</td>
                                    <td>@fat</td>

                                </tr>
                                <tr>
                                    <td>Larry the Bird</td>
                                    <td>@twitter</td>
                                    <td>@twitter</td>
                                    <td>@twitter</td>
                                </tr>


                            </table>
                            <div className="no_content">
                                <img className='mt-3' src={note} alt="" />
                                <b className='mt-2'>Send your first mass message
                                </b>
                                <span style={{ color: "#a1a1aa" }}> Reach your audience at scale.</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default MassMessagesAudience
