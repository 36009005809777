import React, { useEffect, useState } from 'react'
import { IoPencil } from 'react-icons/io5'
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { useDispatch } from 'react-redux';
import { themeColor } from '../../../../../Global/Global';
import { RxCross2 } from "react-icons/rx";
import { FaTrash } from 'react-icons/fa6';
import SweetAlert from '../../../../CommonComponents/SweetAlert';
import { onCreateRequirment, onDeleteRequirment, onUpdateRequirment } from '../../../../../Redux/Actions/CloneActions';

const PurposeRequirement = ({ curElem, index, fetchRequirment, newActive, setNewActive, lastLength, setRequiremnts }) => {
    const dispatch = useDispatch()
    const [req, setReq] = useState(curElem)
    const [reqActive, setReqActive] = useState(false)
    const [loader, setLoader] = useState(false)


    const [sweet, setSweet] = useState({
        enable: false,
        confirmButtonName: "Delete",
        loader: false
    })

    const handleEdit = () => {
        setReqActive(true)
    }
    const handleCancel = () => {
        setReqActive(false)
        setNewActive(false)
        setRequiremnts((draft) => {
            draft.splice(lastLength, 1)
        })
    }

    const handleChange = (e) => {
        setReq({
            ...req,
            requirement: e.target.value
        })
    }

    const handleUpdate = () => {
        let data = { ...req }
        setLoader(true)
        if (newActive) {
            dispatch(onCreateRequirment(data, setReqActive, setNewActive, req, setReq, setLoader))
        } else {
            dispatch(onUpdateRequirment(data, setReqActive, setNewActive, setLoader))
        }
    }

    const onDelete = () => {
        setSweet({
            ...sweet,
            enable: true,
        })
    }

    const onCancelDelete = () => {
        setSweet({
            ...sweet,
            enable: false,
            confirmButtonName: "Delete",
            loader: false
        })
    }

    const performDelete = () => {
        let data = {
            id: req.id,
            cId: req.cId,
            sId: req.sId
        }
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting",
            loader: true
        })
        dispatch(onDeleteRequirment(data, fetchRequirment, setSweet))
    }

    useEffect(() => {
        if (newActive && (+index === +lastLength)) {
            setReqActive(true)
        }
    }, [newActive])

    useEffect(() => {
        setReq(curElem)
    }, [curElem])

    return (
        <div>
            <div className="purpose_main_content mb-4 mt-4" style={{ padding: "0 40px" }}>
                <div className='d-flex align-items-center' style={{ width: "90%" }}>
                    <span className='para_text f-13 me-1'>{index + 1}</span>
                    {reqActive ?
                        <>
                            <input
                                value={req.requirement}
                                onChange={handleChange}
                                style={{ width: "100%", border: `1px solid ${themeColor}`, padding: 10, borderRadius: 15 }}
                            />
                        </>
                        :
                        <p className='ms-2'>{req.requirement} <b style={{ color: "#dc2626", fontSize: "11px" }}>{req.completed}%</b></p>
                    }
                </div>
                <div className='d-flex align-items-center justify-content-end' style={{ width: "10%" }}>
                    {reqActive ?
                        <>

                            {loader ? <>
                                <i className='fa fa-spin fa-spinner' style={{ color: themeColor, fontSize: "15px" }} />
                            </> :
                                <IoIosCheckmarkCircleOutline style={{ color: themeColor, fontSize: "19px" }} onClick={handleUpdate} cursor={"pointer"} title='Update' />
                            }
                            <RxCross2 style={{ color: themeColor, fontSize: "19px" }} onClick={handleCancel} className="ms-2" cursor={"pointer"} title='Cancel' />

                        </>
                        :
                        <>
                            <IoPencil style={{ color: "#71717a", fontSize: "15px" }} onClick={handleEdit} title='Edit' cursor={"pointer"} />
                            <FaTrash className="ms-2" style={{ color: "#71717a", fontSize: "15px" }} title='Delete' cursor={"pointer"} onClick={onDelete} />
                        </>
                    }

                </div>

            </div>
            <hr className='' style={{ opacity: "1", color: "#a1a1aa45" }} />


            <SweetAlert
                show={sweet.enable}
                message={"Are you sure you want to delete this requirement?"}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                alertLoader={sweet.loader}
                cancelButtonName={"Cancel"}
                handleClose={onCancelDelete}
                performDelete={performDelete}
            />

        </div>
    )
}

export default PurposeRequirement