import React from 'react'

const ConversationsAudience = () => {
    return (
        <>
            <p className='mt-3 ms-3'>No data</p>
        </>
    )
}

export default ConversationsAudience
