import React, { useState } from 'react'
import trainingImg from "../../../assets/images/trainingImg.png"
import { Modal } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import { FaSpinner } from 'react-icons/fa';
import { themeColor } from '../../../Global/Global';

const SupportVideo = (props) => {
    const [url, setUrl] = useState('')
    const [showModal, setShowModal] = useState(false);

    const playVideo = (url) => {
        setUrl(url);
        setShowModal(true);
    }

    const closeModal = () => {
        setUrl('');
        setShowModal(false);
    }
    return (

        <div className="video-project">
            <h2 className="text-center">Popular Video Tutorials</h2>
            <div className="video-project-wrap alt">
                <div className="row">
                    {props.videos.length > 0 ?
                        props.videos.map((curElem, index) => {
                            return (
                                <div className="col-lg-3 col-md-6 col-12" key={index} style={{ maxWidth: "100%" }}>
                                    <div className="video-project-single" onClick={() => playVideo(curElem.url)} style={{ cursor: "pointer", width: "100%" }}>
                                        <div className="video-project-img" style={{ height: "auto" }}>
                                            <img src={trainingImg} alt="" />
                                        </div>
                                        <div className="video-project-bottom" style={{ paddingTop: "14px" }}>
                                            <div>
                                                <h6>{curElem.title}</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>)
                        }) :

                        <div className='col-sm-12 mt-3 text-center'>
                            {
                                props.loader.video ?
                                    <FaSpinner className="spin" style={{ fontSize: 25, color: themeColor }} />
                                    : ""
                            }
                        </div>

                    }
                </div>
            </div>
            <Modal className='theme-modal help_model ' show={showModal} onHide={closeModal} centered >
                <Modal.Body className='p_not text-end'>
                    <span className='modalClose' style={{ cursor: "pointer" }} onClick={closeModal}><AiOutlineClose fontSize={25} /></span>
                    <div className="ratio ratio-16x9 mt-2">
                        <iframe id="frameSrc" className="embed-responsive-item" src={url} allowFullScreen title="help-videos"></iframe>
                    </div>
                </Modal.Body>
            </Modal>
        </div>



    )
}

export default SupportVideo