import React from 'react'
import { FaPlus } from 'react-icons/fa6'
import note from '../../../../assets/images/note.png'
const Monetization = () => {
    return (
        <>
            <div className="train_content_wrap" style={{ marginTop: "30px" }}>
                <div className="train_flex" >
                    <div className="train_content_head justify-content-center align-items-start flex-column">
                        <h2>Monetization</h2>
                        <p className=' para_text text-center' style={{ fontWeight: "normal" }} >See how much money your clone has earned.</p>
                    </div>
                </div>
                <hr className='mt-3 mb-4' style={{ opacity: "1", color: "#a1a1aa45" }} />

                <div className="train_table">
                    <div className="table-area mt-3">
                        <div className="table-responsive">
                            <table className="table widget-table">
                                {/* <tr>
                                    <th>Content</th>
                                    <th>Uploaded</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                                <tr>
                                    <td>Mark</td>
                                    <td>Otto</td>
                                    <td>@mdo</td>
                                    <td>@mdo</td>
                                </tr>
                                <tr>
                                    <td>Jacob</td>
                                    <td>Thornton</td>
                                    <td>@fat</td>
                                    <td>@fat</td>

                                </tr>
                                <tr>
                                    <td>Larry the Bird</td>
                                    <td>@twitter</td>
                                    <td>@twitter</td>
                                    <td>@twitter</td>
                                </tr> */}


                            </table>

                        </div>
                    </div>
                    <div className="no_content">
                        <img className='mt-3' src={note} alt="" />
                        <b className='mt-2'>No Memberships?
                        </b>
                        <span style={{ color: "#a1a1aa" }}> Create membership tiers to start earning money on your clone.</span>
                        <button className='theme-btn mt-3'><span>Get Started <FaPlus /></span></button>
                    </div>
                </div>
            </div >
        </>
    )
}

export default Monetization
