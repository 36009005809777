import React from 'react'
import { FaPlus } from 'react-icons/fa6'
import note from '../../../../assets/images/note.png'
const Affiliate = () => {
    return (

        <>
            <div className="train_content_wrap" style={{ marginTop: "30px" }}>
                <div className="train_flex" >

                    <div className="train_content_head justify-content-center align-items-start flex-column">
                        <h2>Affiliate Products</h2>
                        <p className=' para_text text-center' style={{ fontWeight: "normal" }} >See who's speaking to your clone.</p>
                    </div>
                    <div className="d-flex mt-lg-0 mt-3 ms-2">
                        <button className='off-btn me-3'><span>Import </span></button>
                        <button className='theme-btn'><span>Add Product</span></button>
                    </div>
                </div>
                <hr className='mt-3 mb-4' style={{ opacity: "1", color: "#a1a1aa45" }} />

                <div className="train_table">
                    <div className="table-area mt-3">
                        <div className="table-responsive">
                            <table className="table widget-table">
                                <tr>
                                    <th>Content</th>
                                    <th>Uploaded</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                                <tr>
                                    <td>Mark</td>
                                    <td>Otto</td>
                                    <td>@mdo</td>
                                    <td>@mdo</td>
                                </tr>
                                <tr>
                                    <td>Jacob</td>
                                    <td>Thornton</td>
                                    <td>@fat</td>
                                    <td>@fat</td>

                                </tr>
                                <tr>
                                    <td>Larry the Bird</td>
                                    <td>@twitter</td>
                                    <td>@twitter</td>
                                    <td>@twitter</td>
                                </tr>


                            </table>

                        </div>
                    </div>

                </div>
            </div >
        </>
    )

}

export default Affiliate
