import React from 'react'

const ResponsePreferences = () => {
    return (
        <div>
            <div className="settings_wrap">
                <div className="row">
                    <div className="col-12">
                        <div className="settings_head">
                            <h2>Response Preferences</h2>
                        </div>
                    </div>
                    <div className="col-lg-7 mt-3">
                        <div className="settings_content">
                            <h3 className='mb-1' style={{ color: "#000", fontWeight: "500" }}>Default Language</h3>
                            <p className='mb-2 para_text2 f-13'>What is your clone's native languages?</p>
                        </div>
                        <div className="slectBoxWrap">
                            <select className='frequencyBox'>
                                <option value="eng">English</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-7 mt-3">
                <div className="d-flex justify-content-between align-items-center">
                    <div className=" settings_content  mt-3 col-10">
                        <h3 style={{ color: "#000", fontWeight: "500" }}>Recency Bias </h3>
                        <p className="para_text2 f-13">Prioritize more recent document</p>
                    </div>
                    <div className="opt-select">
                        <label className="switch">
                            <input
                                type="checkbox"
                            // checked={selectedSlide.faceNarration.enable}
                            // onChange={handleChangeEnable}
                            />
                            <span className="slider round" style={{ background: "#e4e4e7" }}></span>
                        </label>
                    </div>
                </div>
            </div>
            <div className="col-lg-7 mt-3">
                <div className="d-flex justify-content-between align-items-center">
                    <div className=" settings_content  mt-3 col-10">
                        <h3 style={{ color: "#000", fontWeight: "500" }}>Favour New Content</h3>
                        <p className="para_text2 f-13">Deprioritize content teh user has already seen, so that they can explore new content</p>
                    </div>
                    <div className="opt-select">
                        <label className="switch">
                            <input
                                type="checkbox"
                            // checked={selectedSlide.faceNarration.enable}
                            // onChange={handleChangeEnable}
                            />
                            <span className="slider round" style={{ background: "#e4e4e7" }}></span>
                        </label>
                    </div>
                </div>
            </div>
            <div className="col-lg-7 mt-3">
                <div className="row align-items-center">
                    <div className=" settings_content  mt-3 col-10">
                        <h3 style={{ color: "#000", fontWeight: "500" }}>Creativity</h3>
                        <p className="para_text2 f-13">How strictly the clone adhereas to its content</p>
                    </div>
                    <div className="inp_radio_wrap mt-2">
                        <div className="d-flex justify-content-between mb-3">
                            <p className='para_text2 f-13' style={{ fontWeight: "500" }}>Default</p>
                            <p className='para_text2 f-13' style={{ fontWeight: "600" }}>Reset</p>
                        </div>
                        <div className='range_wrap'>
                            <input type="range" />
                        </div>
                        <div className="d-flex justify-content-between mt-2">
                            <p className='para_text2 f-13' style={{ fontWeight: "500" }}>Low Creativity / High Strictness </p>
                            <p className='para_text2 f-13' style={{ fontWeight: "500" }}>High Creativity / Low Strictness</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-7 mt-3">
                <div className="row align-items-center">
                    <div className=" settings_content  mt-3 col-10">
                        <h3 style={{ color: "#000", fontWeight: "500" }}>Response Length</h3>
                        <p className="para_text2 f-13">Average number of words in a replay</p>
                    </div>
                    <div className="inp_radio_wrap mt-2">
                        <div className="d-flex justify-content-between mb-3">
                            <p className='para_text2 f-13' style={{ fontWeight: "500" }}>Default</p>
                            <p className='para_text2 f-13' style={{ fontWeight: "600" }}>Reset</p>
                        </div>
                        <div className='range_wrap'>
                            <input type="range" />
                        </div>
                        <div className="d-flex justify-content-between mb-2">
                            <p className='para_text2 f-13' style={{ fontWeight: "500" }}>Sort & Concise</p>
                            <p className='para_text2 f-13' style={{ fontWeight: "500" }}>Long & Thorough</p>
                        </div>
                    </div>
                </div>

                <div className="d-flex justify-content-end mt-5">
                    <button className="theme-btn"> <span>Save</span> </button>
                </div>
            </div>
        </div>
    )
}

export default ResponsePreferences;
