import React, { useState } from 'react'
import SweetAlert from '../../../../CommonComponents/SweetAlert'
import { MdOutlinePlayCircle, MdOutlineStopCircle } from 'react-icons/md'
import { themeColor } from '../../../../../Global/Global'
import { useDispatch } from 'react-redux'
import { onDeleteVoice } from '../../../../../Redux/Actions/CloneActions'
import { FaCheck } from "react-icons/fa6";
let music = false
const VoiceRows = ({ curElem, index, fetchVoice, handleUse, ttsData, setTtsData }) => {
    console.log(curElem)
    const dispatch = useDispatch()
    const [play, setPlay] = useState(false)
    const [sweet, setSweet] = useState({
        enable: false,
        confirmButtonName: "Delete",
        loader: false
    })

    const onDelete = () => {
        setSweet({
            ...sweet,
            enable: true,
        })
    }

    const onCancelDelete = () => {
        setSweet({
            ...sweet,
            enable: false,
            confirmButtonName: "Delete",
            loader: false
        })
    }

    const performDelete = () => {
        let data = {
            id: curElem.id,
            type : curElem.type
        }
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting",
            loader: true
        })
        dispatch(onDeleteVoice(data, fetchVoice, setSweet))
    }

    const handlePlay = () => {
        music = new Audio(curElem.url)
        music.play()
            .then(r => {
                setPlay(true)
            }
            )
            .catch(err => console.log(err));
        music.onended = () => {
            setPlay(false)
        }
    }

    const handlePause = () => {
        music.pause()
        setPlay(false)
    }


    const handleUseUpload = () => {
        let newData = {
            ...ttsData,
            type: "voiceover",
            src: curElem.url,
            duration: "",
            meta: {
                text: "",
                language_id: "",
                langId: 13,
                voiceId: "",
                voice_id: "",
                engine: ""
            }
        }
        setTtsData(newData)
        handleUse(newData)
    }


    return (
        <tr>
            <td>{index + 1}</td>
            <td>{curElem.name}</td>
            <td className='text-capitalize'>{curElem.type}</td>
            <td className='d-flex align-items-center'>
                <FaCheck style={{ color: themeColor, fontSize: "20px", cursor: "pointer" }} title='Use' className='me-2' onClick={handleUseUpload} />
                {play ?
                    <MdOutlineStopCircle style={{ color: themeColor, fontSize: "23px", cursor: "pointer" }} title='Stop' onClick={handlePause} /> :
                    <MdOutlinePlayCircle style={{ color: themeColor, fontSize: "23px", cursor: "pointer" }} title='Play' onClick={handlePlay} />
                }
                <i className="fa-solid fa-trash-can ms-2" style={{ color: themeColor, fontSize: "15px", cursor: "pointer" }} onClick={onDelete}></i>
            </td>

            <SweetAlert
                show={sweet.enable}
                message={"Are you sure you want to delete this voice?"}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                alertLoader={sweet.loader}
                cancelButtonName={"Cancel"}
                handleClose={onCancelDelete}
                performDelete={performDelete}
            />
        </tr>
    )
}

export default VoiceRows