import React, { useEffect, useState } from 'react'
import CreateFeedModal from './CreateFeedModal';
import { FaCommentDots } from 'react-icons/fa';
import FeedRow from './FeedRow';
import { onFetchAllFeeds } from '../../../../../Redux/Actions/CloneActions';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import queryString from "query-string"

const Feeds = () => {

    const location = useLocation()
    const dispatch = useDispatch();
    const { cId } = queryString.parse(location.search)
    const studioData = useSelector(state => state.clone.studioData)

    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true)
    const handleClose = () => setShow(false);
    const [feeds, setFeeds] = useState([]);
    const [loader, setLoader] = useState({
        fetch: false
    });

    const fetchFeeds = () => {
        setLoader({ ...loader, fetch: true })
        let data = {
            cId: cId,
            sId: studioData.id
        }
        dispatch(onFetchAllFeeds(data, setFeeds, loader, setLoader))
    }

    useEffect(() => {
        if (cId && studioData.id) {
            fetchFeeds();
        }
    }, [cId, studioData.id])

    return (
        <>
            <div className="voice_content_wrap" style={{ marginTop: "30px" }}>
                <div className="container-fluid" >
                    <div className="row" style={{ padding: "0 20px" }}>
                        <div className="col-12">
                            <div className="train_content_head justify-content-between flex-wrap">
                                <div>
                                    <h2>Feeds</h2>
                                    <p className='mt-1 para_text m-0' >Your clone will auto sync with new content from these feeds.</p>
                                </div>
                                <div>
                                    <button className='off-btn me-3 text-p4 mt-xl-0 mt-lg-2 mt-2' onClick={handleShow} ><span><FaCommentDots style={{ color: '#71717a' }} /> Add</span></button>
                                </div>
                            </div>
                            <hr className='' style={{ opacity: "1", color: "#a1a1aa45" }} />
                        </div>
                    </div>
                    <div>
                        {feeds.length > 0 ?
                            feeds.map((curElem, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <FeedRow
                                            curElem={curElem}
                                            feeds={feeds}
                                            setFeeds={setFeeds}
                                        />
                                    </React.Fragment>
                                )
                            })
                            :
                            <div className='text-center'>
                                {loader.fetch ? <i className='fa fa-spin fa-spinner fetch-loader' /> :
                                    <p>You do not have feeds yet.</p>
                                }
                            </div>
                        }
                    </div>
                </div>
            </div>
            <CreateFeedModal
                show={show}
                handleClose={handleClose}
                fetchFeeds={fetchFeeds}
            />
        </>
    )
}

export default Feeds
