import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { onContinueData } from '../../../../../../Redux/Actions/CloneActions'

const PasteQuestion = (props) => {
    const dispatch = useDispatch()
    const [loader, setLoader] = useState({
        continue: false
    })
    const [obj, setObj] = useState({
        q: "",
        a: ""
    })

    const handleChange = (e) => {
        const { name, value } = e.target
        setObj({
            ...obj,
            [name]: value
        })
    }

    const handleContinue = (e) => {
        e.preventDefault()
        setLoader({
            ...loader,
            continue: true
        })
        let val = {
            cId: props.props.cId,
            sId: props.props.sId,
            sourceId: 6,
            source: "Manual",
            sourceType: props.addQue === "pasteQue" ? "Q&A" : "T&I",
        }

        if (props.addQue === "pasteQue") {
            val = {
                ...val,
                data: {
                    question: obj.q,
                    answere: obj.a
                }
            }
            dispatch(onContinueData("create-manual", val, props.props.fetchContent, props.props.handleClose, loader, setLoader))

        } else {
            val = {
                ...val,
                data: {
                    title: obj.q,
                    information: obj.a
                }
            }
            dispatch(onContinueData("create-manual", val, props.props.fetchContent, props.props.handleClose, loader, setLoader))
        }
    }



    return (
        <>
            <form onSubmit={handleContinue}>
                <div className='d-flex justify-content-between align-items-center mt-2' >
                    <h2 style={{ fontSize: "21px" }}> {props.addQue === "pasteInfo" ? "Paste Information" : "Paste Questions and Answers"}</h2>
                </div>

                <div className="container p-0">
                    <div className="row">
                        <div className="col-12 ">
                            <div className="input-wrap">
                                <label htmlFor="">
                                    {props.addQue === "pasteInfo" ? "Title" : "Question"}
                                </label>
                                <div className="input-outer">
                                    <input
                                        className="common-inp alt"
                                        type="text"
                                        placeholder="Question"
                                        name='q'
                                        value={obj.q}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="input-wrap">
                                <label htmlFor="">
                                    {props.addQue === "pasteInfo" ? "Information" : "Answer"}
                                </label>
                                <div div className="input-outer">
                                    <textarea
                                        className="common-inp alt"
                                        name='a'
                                        value={obj.a}
                                        onChange={handleChange}
                                        rows={7}
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12 text-end mt-4">
                    <button className='theme-btn me-3 text-p4' type='submit'><span>Continue {loader.continue ? <i className='fa fa-spin fa-spinner' /> : ""}</span></button>
                </div>
            </form >

        </>
    )
}

export default PasteQuestion
