import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import noteImg from '../../../../../src/assets/images/note.png';
import { AiOutlineClose } from 'react-icons/ai';
import { GiSparkles } from "react-icons/gi";
import { FaPlusCircle } from 'react-icons/fa';
import { FaMicrochip } from "react-icons/fa6";
import { ImPlus } from "react-icons/im";
import { PiFan } from "react-icons/pi";
import { HiLightBulb } from "react-icons/hi";
import { FaPenFancy } from "react-icons/fa";
import { RiUserReceived2Fill } from "react-icons/ri";
import { BsSuitHeartFill } from "react-icons/bs";
import { CiMusicNote1 } from "react-icons/ci";
import { GoLaw } from "react-icons/go";
import { MdOutlineTempleBuddhist } from "react-icons/md";
import { PiPaintBrushHouseholdDuotone } from "react-icons/pi";
import { GrTrophy } from "react-icons/gr";
import { VscStarEmpty } from "react-icons/vsc";
import { FaTheaterMasks } from "react-icons/fa";
import { PiFilmSlateDuotone } from "react-icons/pi";
import { AiOutlineStock } from "react-icons/ai";
import { GiGraduateCap } from "react-icons/gi";
import { GiTwoCoins } from "react-icons/gi";
import { LuMegaphone } from "react-icons/lu";
import { onChangeCloneSettingBio, onSaveCloneBio } from '../../../../Redux/Actions/CloneSettingAction';
import { themeColor } from '../../../../Global/Global';
import Swal from 'sweetalert2';
import { useDispatch } from 'react-redux';
import { fetchData, onUploadMedia } from '../../../../Redux/Actions/CommonActions';
import { RxCross2 } from 'react-icons/rx';

const CloneBio = (props) => {

    const dispatch = useDispatch();
    const [org, SetOrg] = useState(false);
    const { cloneBio, cId } = props.settingData;

    const [topics, setTopics] = useState([]);
    const [isEditable, setIsEditable] = useState(false);
    const [percent, setPercent] = useState(0);
    const [loader, setLoader] = useState({
        save: false,
        upload: false,
        create: false
    });

    const handleCreateTopic = (e) => {
        const { value } = e.target;
        if (value && isEditable === true) {
            let data = {
                cId: cId,
                topic: value
            }
            setLoader({ ...loader, create: true })
            // dispatch(onCreateTopic(data, state, setState, loader, setLoader))
            setIsEditable(false)
        }
    };

    const handleAdd = (name) => {
        if (cloneBio.selectedTopic.includes(name)) {
            let newTopics = cloneBio.selectedTopic.filter((curElem) => {
                return curElem !== name
            })
            dispatch(onChangeCloneSettingBio("selectedTopic", newTopics))
        } else {
            let data = [...cloneBio.selectedTopic]
            data.push(name)
            dispatch(onChangeCloneSettingBio("selectedTopic", data))
        }
    }

    const getProfileImage = (url, type) => {
        dispatch(onChangeCloneSettingBio(type, url))
    }

    const onInputChange = (e) => {
        let allowedExt = ['image/png', 'image/jpg', 'image/jpeg']
        const formData = new FormData()
        if (e.target.files.length > 0) {
            if (allowedExt.includes(e.target.files[0].type)) {
                if (e.target.files[0].size < 5000000) {
                    formData.append('upload_type', "images")
                    formData.append('file', e.target.files[0])
                    setLoader({ ...loader, upload: true })
                    dispatch(onUploadMedia(formData, getProfileImage, loader, setLoader, setPercent, "profile"))
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Max allowed size for images is 5MB!',
                        confirmButtonColor: themeColor
                    })
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'You have Selected Invalid File Type!',
                    confirmButtonColor: themeColor
                })
            }
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        dispatch(onChangeCloneSettingBio(name, value))
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoader({ ...loader, save: true })
        let data = { ...cloneBio }
        dispatch(onSaveCloneBio(data, loader, setLoader))
    }

    const fetchTopics = () => {
        dispatch(fetchData("fetch-topics", {}, setTopics))
    }

    useEffect(() => {
        fetchTopics()
    }, [])


    return (
        <div>
            <div className="settings_head">
                <h2>Clone Bio</h2>
            </div>
            <form action="" onSubmit={handleSubmit}>
                <div className="col-xl-7 col-md-9 col-12 mt-5">
                    <div className="settings_content">
                        <h3>HeadLine</h3>
                        <p className='mb-2 para_text2'>Displayed in various places. Similar to Linkedin headline. Max 120 characters</p>
                        <div className="setting_input">
                            <div className="input-outer">
                                <input
                                    className="common-inp alt"
                                    type="text"
                                    placeholder="HeadLine"
                                    name='headLine'
                                    value={cloneBio.headLine}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    <div className="settings_content mt-5">
                        <div className="d-flex align-items-center justify-content-xl-start justify-content-center flex-wrap mt-2 mb-2">
                            <h3>Description</h3>
                            <p className='P_tags mt-xxl-0' style={{ marginLeft: "6px" }}> <GiSparkles className='me-1' /> This will be used in traning in your clone</p>
                        </div>
                        <p className='mb-2 para_text2'>A longer overview of this clone and this will be displayed on your clone's profile page.</p>
                        <div className="setting_input">
                            <div className="input-outer">
                                <div className="input-group" style={{ flexWrap: "Nowrap" }}>
                                    <textarea
                                        className="common-inp alt"
                                        rows="4"
                                        type="text"
                                        placeholder="Description"
                                        name='description'
                                        value={cloneBio.description}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="setting_btn text-end mt-4">
                        <button type='submit' disabled={loader.save} className='theme-btn me-0'><span>{loader.save ? <>Saving <i className='fa fa-spin fa-spinner' /></> : "Save"}</span></button>
                    </div>
                </div>
            </form>

            <div className="col-xl-7 col-md-9 col-12">
                <div className="settings_content">
                    <h3 className=''>Topics</h3>
                    <p className='mb-2 para_text2 f-13'>Select 1-5 topics that are relevant to your clone's realm of expertise. Helps calculate readiness score.</p>
                    <div className='topics_wrap mt-3'>
                        {topics.length > 0 ?
                            topics.map((curElem, index) => {
                                return (
                                    <div className="me-3 mb-3" key={index}>
                                        <div className={`topics_elm text-center ${cloneBio.selectedTopic.includes(curElem.name) ? "topics_elm_active" : ""}`} onClick={() => handleAdd(curElem.name)}><i className={`fa-solid me-2 ${curElem.icon}`}></i>{curElem.name}</div>
                                    </div>
                                )
                            })
                            : ""
                        }
                        <div className="me-3 mb-3">
                            <div className='topics_elm text-center'> <GiTwoCoins className="me-2" style={{ fontSize: "16px" }} />Finance</div>
                        </div>
                        <div className="me-3 mb-3">
                            <div className='topics_elm text-center'> <FaMicrochip className='me-2' style={{ fontSize: "16px" }} />Technology</div>
                        </div>
                        <div className="me-3 mb-3">
                            <div className='topics_elm text-center'> <ImPlus className='me-2' style={{ fontSize: "16px" }} />Health</div>
                        </div>
                        <div className="me-3 mb-3">
                            <div className='topics_elm text-center'> <HiLightBulb className='me-2' style={{ fontSize: "16px" }} />Entrepreneurship</div>
                        </div>
                        <div className="me-3 mb-3">
                            <div className='topics_elm text-center'> <PiFan className="me-2" style={{ fontSize: "16px" }} />Science</div>
                        </div>
                        <div className="me-3 mb-3">
                            <div className='topics_elm text-center'> <FaPenFancy className="me-2" style={{ fontSize: "16px" }} />Design</div>
                        </div>
                        <div className="me-3 mb-3">
                            <div className='topics_elm text-center'> <RiUserReceived2Fill className='me-2' style={{ fontSize: "16px" }} />Psychology</div>
                        </div>
                        <div className="me-3 mb-3">
                            <div className='topics_elm text-center'><LuMegaphone className="me-2" style={{ fontSize: "16px" }} />Marketing</div>
                        </div>
                        <div className="me-3 mb-3">
                            <div className='topics_elm text-center'><BsSuitHeartFill className="me-2" style={{ fontSize: "16px" }} />Wellness</div>
                        </div>
                        <div className="me-3 mb-3">
                            <div className='topics_elm text-center'> <CiMusicNote1 className="me-2" style={{ fontSize: "16px" }} />Music</div>
                        </div>
                        <div className="me-3 mb-3">
                            <div className='topics_elm text-center'><GoLaw className="me-2" style={{ fontSize: "16px" }} />Law</div>
                        </div>
                        <div className="me-3 mb-3">
                            <div className='topics_elm text-center'> <MdOutlineTempleBuddhist className='me-2' style={{ fontSize: "16px" }} />Religion</div>
                        </div>
                        <div className="me-3 mb-3">
                            <div className='topics_elm text-center'> <PiPaintBrushHouseholdDuotone className="me-2" style={{ fontSize: "16px" }} />Art</div>
                        </div>
                        <div className="me-3 mb-3">
                            <div className='topics_elm text-center'> <GrTrophy className="me-2" style={{ fontSize: "16px" }} />Sports</div>
                        </div>
                        <div className="me-3 mb-3">
                            <div className='topics_elm text-center'> <VscStarEmpty className='me-2' style={{ fontSize: "16px" }} />Beauty</div>
                        </div>
                        <div className="me-3 mb-3">
                            <div className='topics_elm text-center'> <FaTheaterMasks className="me-2" style={{ fontSize: "16px" }} />Theater</div>
                        </div>
                        <div className="me-3 mb-3">
                            <div className='topics_elm text-center'><PiFilmSlateDuotone className='me-2' style={{ fontSize: "16px" }} />Entertainment</div>
                        </div>
                        <div className="me-3 mb-3">
                            <div className='topics_elm text-center'> <AiOutlineStock className="me-2" style={{ fontSize: "16px" }} />Sales</div>
                        </div>
                        <div className="me-3 mb-3">
                            <div className='topics_elm text-center'> <GiGraduateCap className="me-2" style={{ fontSize: "16px" }} />Education</div>
                        </div>
                        {
                            cloneBio.customTopicArray.length > 0 ?
                                cloneBio.customTopicArray.map((curElem) => {
                                    return (
                                        <div className="me-3 mb-3">
                                            <div className={`topics_elm text-center ${cloneBio.selectedTopic.includes(curElem) ? "topics_elm_active" : ""}`}>{curElem}</div>
                                        </div>
                                    )
                                }) : ""
                        }
                        {loader.create ?
                            <div className='mt-2'>
                                <i className='fa fa-spin fa-spinner' style={{ color: themeColor, fontSize: "13px" }} />
                            </div>
                            : ""
                        }
                    </div>


                    <div className="setting_btn text-start mt-4">
                        {isEditable ?
                            <>
                                <input
                                    type="text"
                                    name='content'
                                    placeholder='Enter Topic'
                                    onKeyUp={(event) =>
                                        event.key === "Enter" ? handleCreateTopic(event) : null
                                    }
                                />
                                <RxCross2 onClick={() => setIsEditable(false)} />
                            </>
                            :
                            <button type='button' className='off-btn me-0' style={{ color: "#71717a" }} onClick={() => setIsEditable(true)}><span> <FaPlusCircle className="me-2" />Add your own</span></button>
                        }
                    </div>
                </div>
            </div>
            <div className="col-xl-7 col-md-9 col-12">
                <hr className='' style={{ opacity: "1", color: "#a1a1aa45", marginTop: "30px", }} />
            </div>
            <div className="col-xl-7 col-md-9 col-12">
                <div className="settings_content mt-3">
                    <h3 className=''>Organizations</h3>
                    <p className='mb-2 para_text2' >Add organization your clone is associated with such as a company, nonprofit, or school</p>
                    <div className='org_wrap mt-3'>
                        <h3 className='org_head' style={{ padding: "10px 20px" }}> <i class="fa-solid fa-flag pe-2"></i>Added organization</h3>
                        <div className="org_body_content">
                            <div className='org_body'>
                                <div className="img-Wrap py-3 text-center">
                                    <img src={noteImg} alt="note" className='img-fluid' />
                                </div>
                                <div className="btn-wrap text-center pb-3">

                                    <button className='theme-btn' onClick={() => SetOrg(true)}> <span>Add Organization</span>
                                    </button>
                                </div>
                            </div>

                            <div className="org_footer row justify-content-center pt-2 pb-2">
                                <p className=' col-lg-6 text-center'>You can drag and drop and choose the ordering of the organization displayed and you profile</p>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <Modal className='theme-modal video-modal feed' show={org} onHide={() => SetOrg(false)} centered>
                <Modal.Body className="p-0">
                    <div className='modalClose text-end' onClick={() => SetOrg(false)}><AiOutlineClose style={{ fontSize: "20px", marginBottom: "3px" }} /></div>

                    <div className="row">
                        <div className="col-12">
                            <div className="settings_head">
                                <h2>Clone profile</h2>
                            </div>
                            <div className="setting_upload_img mt-5">
                                <span> <img src="https://images.pexels.com/photos/2169434/pexels-photo-2169434.jpeg" alt="" /></span>
                                <div className="setting_upload_input ms-4">
                                    <button className='off-btn'>Upload new image</button>
                                    <input type="file" />
                                </div>
                            </div>


                        </div>
                        <div className="col-12 mt-5">
                            <div className="settings_content">
                                <h3 className='mb-1'>Clone Name</h3>
                                <p className='mb-2 para_text'>This is your unique url. Ideally, this should match your name and/or your handle on another</p>
                                <div className="setting_input">
                                    <div className="input-outer">
                                        <input
                                            className="common-inp alt"
                                            type="email"
                                            placeholder="Dana"
                                            name='email'
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal >
        </div >
    )
}

export default CloneBio
