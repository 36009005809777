import React, { useEffect, useState } from 'react'
import { themeColor } from '../../../../../Global/Global'
import { useDispatch } from 'react-redux'
import { onCheckCompelete } from '../../../../../Redux/Actions/CloneActions'
import { FaTrash } from 'react-icons/fa'
import { MdEdit } from 'react-icons/md'
import { Link } from 'react-router-dom'
import SweetAlert from '../../../../CommonComponents/SweetAlert'
import { onDeleteCampaign } from '../../../../../Redux/Actions/CommonActions'
import UpdateContent from './UpdateContent'

const ContentRows = ({ curElem, index, fetchData, contentData, setContentData }) => {
    const dispatch = useDispatch()

    const [show, setShow] = useState(false)
    const handleShow = () => setShow(true)
    const handleClose = () => setShow(false)

    const [sweet, setSweet] = useState({
        enable: false,
        confirmButtonName: "Delete",
        loader: false
    })

    const handleView = () => {
        window.open(curElem.url, "_blank")
    }


    const onDelete = () => {
        setSweet({
            ...sweet,
            enable: true,
        })
    }

    const onCancelDelete = () => {
        setSweet({
            ...sweet,
            enable: false,
            confirmButtonName: "Delete",
            loader: false
        })
    }

    const performDelete = () => {
        let data = {
            id: curElem.id,
            cId: curElem.cId,
            sId: curElem.sId

        }
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting",
            loader: true
        })
        dispatch(onDeleteCampaign("delete-content", data, contentData, setContentData, setSweet))
    }





    useEffect(() => {
        if (+curElem.status === 1) {
            const interval = setInterval(() => {
                let data = {
                    id: curElem.id,
                    cId: curElem.cId
                }
                dispatch(onCheckCompelete(data, fetchData, interval))
            }, 5000)

            return () => {
                clearInterval(interval)
            }
        }
    }, [curElem])

    return (
        <tr>
            <td>{index + 1}</td>
            <td>{curElem.source}</td>
            <td><span className='view-button' style={{ background: themeColor, cursor: "pointer" }} onClick={handleView}>View</span></td>
            <td>
                <span className='view-button'
                    style={{ background: +curElem.status === 1 ? "#fbaa53" : +curElem.status === 2 ? "green" : "red" }}>
                    {+curElem.status === 1 ? "Processing..." : +curElem.status === 2 ? "Completed" : "Failed"}
                </span>
            </td>
            <td>
                {+curElem.status !== 1 ?
                    <span onClick={handleShow}>
                        <MdEdit title='Edit' style={{ color: "#616161", cursor: "pointer" }} />
                    </span>
                    : null}
                <span className='ms-2' onClick={onDelete}>
                    <FaTrash title='Delete' style={{ color: "#616161", cursor: "pointer" }} />
                </span>
            </td>


            <UpdateContent
                curElem={curElem}
                show={show}
                handleClose={handleClose}
                fetchData={fetchData}
            />

            <SweetAlert
                show={sweet.enable}
                message={"Are you sure you want to delete this content?"}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                alertLoader={sweet.loader}
                cancelButtonName={"Cancel"}
                handleClose={onCancelDelete}
                performDelete={performDelete}
            />
        </tr>
    )
}

export default ContentRows