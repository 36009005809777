import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { AiOutlineClose } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import queryString from "query-string"
import { onCreateFeed } from '../../../../../Redux/Actions/CloneActions';

const CreateFeedModal = ({ show, handleClose, fetchFeeds }) => {

    const location = useLocation()
    const dispatch = useDispatch();
    const { cId } = queryString.parse(location.search)
    const studioData = useSelector(state => state.clone.studioData)

    const [state, setState] = useState({
        type: "youtube",
        url: ""
    });
    const [loader, setLoader] = useState({
        save: false
    });

    const handleChange = (e) => {
        const { name, value } = e.target
        setState({ ...state, [name]: value })
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoader({ ...loader, save: true })
        let data = {
            cId: cId,
            sId: studioData.id,
            type: state.type,
            url: state.url
        }
        dispatch(onCreateFeed(data, loader, setLoader, handleClose, fetchFeeds))
    }
    useEffect(() => {
        if (show) {
            setState({
                type: "youtube",
                url: ""
            })
        }
    }, [show])

    return (
        <Modal className='theme-modal video-modal feed' show={show} onHide={handleClose} centered>
            <Modal.Body className="p-0">
                <div className='modalClose text-end' onClick={handleClose}><AiOutlineClose style={{ fontSize: "20px", marginBottom: "3px" }} /></div>
                <div className="feed_warp" style={{ marginTop: "10px" }}>
                    <div className="container">
                        <form action="" onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="col-12">
                                    <div className="feed_mod_head">
                                        <h2 className='mb-3'>Add a Feed</h2>
                                        <p className='mt-1 mb-1 para_text'>Feed type</p>
                                    </div>
                                    <div className="input-outer w-100">
                                        <select
                                            className="common-inp alt"
                                            placeholder='Type'
                                            name="type"
                                            value={state.type}
                                            onChange={handleChange}
                                            required
                                        >
                                            <option value="youtube">Youtube</option>
                                            {/* <option value="podcast">Podcast</option> */}
                                            <option value="twitter">Twitter</option>
                                            <option value="blog">Blog</option>
                                        </select>

                                    </div>
                                    <div>
                                        <h2 className='mt-3 mb-1'><span className='text-capitalize'>{state.type}</span> URL</h2>
                                        <input
                                            className="common-inp alt"
                                            type="text"
                                            placeholder="Enter URL here"
                                            name="url"
                                            value={state.url}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div className="feed_btn text-end">
                                        <button type='submit' disabled={loader.save} className='theme-btn me-3 text-p4 mt-3'><span>{loader.save ? <>Saving <i className='fa fa-spin fa-spinner' /></> : "Save"}</span></button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div >
            </Modal.Body>
        </Modal >
    )
}

export default CreateFeedModal
