import axios from "axios"
import { commonAxios } from "../../Global/CommonAxios"
import { setAlert } from "./AlertActions"
import WebFont from "webfontloader"

export const fetchData = (url, data, setState, loader, setLoader, limit, isShort) => (dispatch, getState) => {
    commonAxios(url, data, dispatch, getState().auth.token).
        then((res) => {
            if (res.status) {
                let arr = [...res.data]
                if (limit) {
                    arr = arr.slice(0, limit)
                }
                if (isShort === "shortDataCt") {
                    arr.sort((a, b) => {
                        if (a.country_name > b.country_name) {
                            return 1
                        } else {
                            return -1
                        }
                    })
                }
                if (isShort === "shortDataLg") {
                    arr.sort((a, b) => {
                        if (a.name > b.name) {
                            return 1
                        } else {
                            return -1
                        }
                    })
                }
                setState(arr)
            }
            else {
                setState([])
            }
            if (loader) {
                setLoader({
                    ...loader,
                    fetch: false
                })
            }
        }).catch((err) => {
            if (loader) {
                setLoader({
                    ...loader,
                    fetch: false
                })
            }
            console.log(err)
        })
}

export const fetchMedia = (url, data, state, setState, loader, setLoader, setError) => (dispatch, getState) => {
    commonAxios(url, data, dispatch, getState().auth.token).
        then((res) => {
            if (res.status) {
                if (data.page_number === 1) {
                    setState(res.data)
                } else {
                    let arr = [...state]
                    setState(arr.concat(res.data))
                }
            }
            else {
                // setState([])
                if (setError) {
                    setError(res.msg)
                }
            }
            setLoader({
                ...loader,
                fetch: false
            })
        }).catch((err) => {
            setLoader({
                ...loader,
                fetch: false
            })
            console.log(err)
        })
}

export const onUploadMedia = (data, fetchMedia, loader, setLoader, setPercent, type, handleClose) => (dispatch, getState) => {
    const config = {
        onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            let percent = Math.floor((loaded * 100) / total);
            setPercent(percent);
        },
        headers: {
            'Authorization': getState().auth.token
        },
    }
    setLoader({
        ...loader,
        upload: true
    })

    commonAxios("upload-file", data, dispatch, false, config)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
                if (type) {
                    fetchMedia(res.data.path, type, res.data.thumbnail)
                } else {
                    fetchMedia()
                }
                if (handleClose) {
                    handleClose()
                }
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setPercent(0)
            setLoader({
                ...loader,
                upload: false
            })
        }).catch((err) => {
            setLoader({
                ...loader,
                upload: false
            })
            console.log(err)
        })
}

export const onCheckStatus = (data, common, setCommon, interval) => (dispatch, getState) => {

    commonAxios("fetch-video", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let statusNum = +res.data.status
                if (statusNum === 2 || statusNum === 3 || statusNum === 4 || statusNum === 6) {
                    clearInterval(interval)
                    let text = ""
                    let redirectUrl = ""
                    switch (statusNum) {
                        case 2:
                            text = "Render Successful"
                            redirectUrl = "editor"
                            break;
                        case 3:
                            text = "Rendering Failed"
                            redirectUrl = "editor"
                            break;
                        case 4:
                            text = "Script Generated"
                            redirectUrl = "create-script"
                            break;
                        case 6:
                            text = "Awaiting Customization"
                            redirectUrl = "editor"
                            break;
                        default:
                            text = ""
                    }
                    setCommon({
                        ...common,
                        statusText: text,
                        isEditabel: true,
                        isDownloadable: res.data.videoUrl,
                        showRendering: false,
                        urlToRedirect: redirectUrl,
                        showProgAnimation: false,
                        thumbUrl: res.data.thumbnailUrl,
                        failedData: res.data.logFile ? JSON.parse(res.data.logFile) : false
                    })
                }
                else if (statusNum === 9) {
                    setCommon({
                        ...common,
                        statusText: "Rendering",
                        showRendering: true,
                        progress: res.data.renderProgress,
                        isEditabel: true,
                        isDownloadable: res.data.videoUrl,
                        thumbUrl: res.data.thumbnailUrl,
                        urlToRedirect: "editor"
                    })
                }
                else if (statusNum === 8) {
                    setCommon({
                        ...common,
                        statusText: res.data.renderProgress,
                        showRendering: false,
                        showProgAnimation: true,
                        isEditabel: true,
                        isDownloadable: res.data.videoUrl,
                        thumbUrl: res.data.thumbnailUrl,
                        urlToRedirect: "editor"
                    })
                }
                else if (statusNum === 5) {
                    if (res.data.slides.length > 0) {
                        setCommon({
                            ...common,
                            statusText: `Generating Slides ${res.data.slides.length}/${+res.data.totalSlides}`,
                            showRendering: false,
                            showProgAnimation: true,
                            isEditabel: true,
                            isDownloadable: res.data.videoUrl,
                            thumbUrl: res.data.thumbnailUrl,
                            urlToRedirect: "editor",
                            progress: parseInt((res.data.slides.length / +res.data.totalSlides) * 100)

                        })
                    }

                }
            }

        }).catch((err) => {
            clearInterval(interval)
            console.log(err)
        })
}

export const onFetchScript = (data, setState, setDimension, setLoader) => (dispatch, getState) => {
    commonAxios("fetch-video", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setState(res.data.savedScript ? res.data.savedScript : res.data.originalScript)
                setDimension(res.data.dimension)
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader(false)
        }).catch((err) => {
            console.log(err)
            setLoader(false)
        })
}

export const onUpdateScript = (data, navigate, dimension, loader, setLoader) => (dispatch, getState) => {
    commonAxios("update-script", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                if (data.type === "save") {
                    dispatch(setAlert(res.msg, "success"))
                } else {
                    navigate(`/projects?d=${dimension === "16x9" ? "hz" : "vc"}`)
                }
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                next: false,
                save: false
            })
        }).catch((err) => {
            console.log(err)
            setLoader({
                ...loader,
                next: false,
                save: false
            })
        })
}

export const onGenerateScript = (data, state, setState, setGeneLoader, interval) => (dispatch, getState) => {
    commonAxios("update-scrap-script", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setState({
                    ...state,
                    preScript: res.data.preScript,
                    media: JSON.parse(res.data.media),
                    errorMessage: ""
                })
            } else {
                setState({
                    ...state,
                    errorMessage: res.msg,
                })
                dispatch(setAlert(res.msg, "danger"))
            }
            clearInterval(interval)
            setGeneLoader(false)
        }).catch((err) => {
            clearInterval(interval)
            console.log(err)
            setGeneLoader(false)
        })
}


export const onGenerateAi = (data, setImage, loader, setLoader) => (dispatch, getState) => {
    commonAxios("generate-ai-image", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setImage(res.data)
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                generate: false
            })
        }).catch((err) => {
            setLoader({
                ...loader,
                generate: false
            })
            console.log(err)
        })
}

export const onFetchFont = (setFonts, toLoad) => (dispatch) => {
    const fontNotSet = ["Helvetica", "Bebas", "palatino linotype", "Subjectivity", "LEMON MILK", "Gill Sans Ultra Bold", "Gilroy", "MS Reference Sans Serif", "MS Serif"
        , "Microsoft JhengHei", "Times New Roman", "MS UI Gothic", "Bookman Old Style", "Franklin Gothic Book", "Gill Sans MT", "Eras Light ITC", "Microsoft Sans Serif", "Microsoft YaHei UI", "Helvetica Now Display", "Gadugi", "Tw Cen MT"
        , "Ebrima", "Subjectivity Serif", "Clarendon", "Segoe UI Emoji", "Fashion Fetish Heavy", "Bastian Sidney", "Palatino Linotype", "Integral CF", "Steelfish", "Monument Extended", "Metropolis", "SignPainter HouseScript", "ChunkFive", "Franklin Gothic", "Agency FB", "Marchelina Script", "Franklin Gothic Medium", "Nexa Bold", "Spartwell", "Spartwell"
        , "Akira Expanded", "Calibri", "Zeyada", "Droid Serif", "Droid Sans Mono", "Droid Sans"]

    axios({
        method: 'POST',
        url: 'https://adminserver.reelapps.io/fetch-fonts',
        data: {},
        headers: { 'Content-Type': 'application/json' }
    }).then(res => {
        if (res.data.status === true) {
            let arr = res.data.data.filter(curElem => {
                return !fontNotSet.includes(curElem.value)
            }).map((item) => {
                return (
                    item.value
                )
            })
            arr = arr.concat('Inter')

            arr = arr.sort((a, b) => {
                return a > b ? 1 : -1
            })
            if (toLoad) {
                WebFont.load({
                    google: {
                        families: arr
                    }
                })
            } else {
                setFonts(arr);
            }
        }
    }).catch(err => {
        console.log(err)
    })
}

export const onDeleteMedia = (formData, fetchData, setSweet) => (dispatch, getState) => {
    commonAxios("delete-user-uploaded-file", formData, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                fetchData()
                dispatch(setAlert(res.msg, 'success'));
            }
            setSweet({
                enable: false,
                id: false,
                confirmButtonName: "Delete",
                loader: false
            })
        }).catch((error) => {
            setSweet({
                enable: false,
                id: false,
                loader: false,
                confirmButtonName: "Delete"
            })
            console.log(error)
        })
}

export const onFetchPlatform = (url, data, setState) => (dispatch, getState) => {
    commonAxios(url, data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setState(res.data)
            }
        }).catch((err) => {
            console.log(err)
        })
}

export const onDeleteCampaign = (url, data, videos, setVideos, setSweet) => (dispatch, getState) => {

    commonAxios(url, data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setVideos(videos.filter((curElem) => {
                    return +curElem.id !== +data.id
                }))
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setSweet({
                enable: false,
                id: false,
                confirmButtonName: "Delete",
                loader: false
            })
        }).catch((err) => {
            console.log(err)
            setSweet({
                enable: false,
                id: false,
                confirmButtonName: "Delete",
                loader: false
            })
        })
}

export const onTranslate = (data, state, setState, setLoader) => (dispatch, getState) => {
    commonAxios("translate", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setState({
                    ...state,
                    script: res.data.text
                })
            }
            else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader(false)
        }).catch((err) => {
            setLoader(false)
            dispatch(setAlert(err.msg, "danger"))

        })
}

export const onFetchProjects = (setProejcts, limit, setLoader) => (dispatch, getState) => {
    commonAxios("get-campaign", {}, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let data = res.data
                if (limit) {
                    data = data.slice(0, limit)
                }
                setProejcts(data)
            } else {
                setProejcts([])
            }
            setLoader(false)
        }).catch((err) => {
            console.log(err)
            setLoader(false)

        })
}

export const onMergingVideo = (data, navigate, setLoader) => (dispatch, getState) => {
    commonAxios("create-merge", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                navigate(`/projects?d=${data.ratio === "16:9" ? "hz" : "vc"}&p=${2}`)
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader(false)
        }).catch((err) => {
            console.log(err)
            setLoader(false)

        })
}

export const onPublishVideo = (type, data, handleClose, setLoader) => (dispatch, getState) => {
    commonAxios(type, data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            handleClose(false);
            setLoader(false);
        }).catch((err) => {
            console.log(err)
            handleClose(false);
            setLoader(false);
        })
}