import React from 'react'

const Style = ({ handleUpdate, data, setData, loader }) => {

    const handleChange = (e) => {
        const { name, value } = e.target
        setData((draft) => {
            draft[name] = value
        })
    }

    return (
        <div>
            <h3>Set a Style for a Clone</h3>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>

            <div className='clone-style'>
                <div className='clone-style-single'>
                    <h5>Creativity</h5>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                    <div className='clone-style-txt mt-3'>
                        <span><strong>Reset</strong></span>
                        <span className='default'><strong>Default</strong></span>
                    </div>
                    <div className="slidecontainer full">
                        <input
                            type="range"
                            min="0"
                            max="100"
                            className="sliderN"
                            name='creativity'
                            value={data.creativity}
                            onChange={handleChange}
                        />
                    </div>
                    <div className='clone-style-txt mt-2'>
                        <span><small>Low Creativity / High Success</small></span>
                        <span><small>High Creativity / Low Success</small></span>
                    </div>
                </div>

                <div className='clone-style-single'>
                    <h5>Response Length</h5>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                    <div className='clone-style-txt mt-3'>
                        <span><strong>Reset</strong></span>
                        <span className='default'><strong>Default</strong></span>
                    </div>
                    <div className="slidecontainer full">
                        <input
                            type="range"
                            min="0"
                            max="100"
                            className="sliderN"
                            name='responseLength'
                            value={data.responseLength}
                            onChange={handleChange}
                        />
                    </div>
                    <div className='clone-style-txt mt-2'>
                        <span><small>Short & Concise</small></span>
                        <span><small>Long & </small></span>
                    </div>
                </div>

            </div>

            <div className='input-wrap'>
                <button className='theme-btn full' onClick={() => handleUpdate(null)}><span>{loader.submit ? <>Continue <i className='fa fa-spin fa-spinner' /></> : "Continue"} </span></button>
            </div>
        </div>
    )
}

export default Style