import React from 'react'
import Memberships from './Memberships'
import { Nav, Button, Tab } from 'react-bootstrap';
import Affiliate from './Affiliate';
const MonetizationMain = () => {
    return (
        <>
            <div className='studio-right' style={{ width: "100%" }}>
                <div className='pageTitle flex'>
                    <h2>Audience</h2>

                </div>
                <Tab.Container id="tabs-example" defaultActiveKey="first">
                    <div className=" col-xl-5 col-12 ">
                        <Nav variant="pills" className="welcome-studio justify-content-start">
                            <Nav.Item>
                                <Nav.Link eventKey="first">Memberships</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="second">Affiliate Products</Nav.Link>
                            </Nav.Item>


                        </Nav>
                    </div>
                    <div className="col-12">
                        <Tab.Content>
                            <Tab.Pane eventKey="first">
                                <Memberships />
                            </Tab.Pane>
                            <Tab.Pane eventKey="second">
                                <Affiliate />
                            </Tab.Pane>


                        </Tab.Content>
                    </div>

                </Tab.Container>
            </div>

        </>
    )
}

export default MonetizationMain
