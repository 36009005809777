import React from 'react';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts';
const Activity = () => {
    const data = [
        {
            name: 'Page A',
            uv: 4000,
            pv: 2400,
            amt: 2400,
        },
        {
            name: 'Page B',
            uv: 3000,
            pv: 1398,
            amt: 2210,
        },
        {
            name: 'Page C',
            uv: 2000,
            pv: 9800,
            amt: 2290,
        },
        {
            name: 'Page D',
            uv: 2780,
            pv: 3908,
            amt: 2000,
        },
        {
            name: 'Page E',
            uv: 1890,
            pv: 4800,
            amt: 2181,
        },
        {
            name: 'Page F',
            uv: 2390,
            pv: 3800,
            amt: 2500,
        },
        {
            name: 'Page G',
            uv: 3490,
            pv: 4300,
            amt: 2100,
        },
    ];
    return (
        <>
            <div className="train_content_wrap" style={{ marginTop: "30px" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="train_flex" >
                                <div className="review-box mt-3 w-100" >
                                    <div className="input-outer w-100">
                                        <div className="d-flex justify-content-between flex-wrap w-100">
                                            <div>
                                                <select
                                                    className="common-inp alt"
                                                    name="ratingsSort"
                                                    placeholder='Messages'
                                                    style={{ width: "200px", fontSize: "17px" }}
                                                // value={filterData.ratingsSort}
                                                // onChange={(e) => handleChange(e, "ratingsSort")}
                                                >
                                                    <option value="">Messages</option>
                                                    <option value="ascending">Conversations</option>
                                                    <option value="descending">Unique Users</option>
                                                </select>
                                            </div>
                                            <div className='d-flex align-items-center mt-md-0 mt-3'>

                                                <select
                                                    className="common-inp alt"
                                                    name="ratingsSort"
                                                    placeholder='Last 7 Days'
                                                    style={{ width: "200px", fontSize: "17px" }}
                                                // value={filterData.ratingsSort}
                                                // onChange={(e) => handleChange(e, "ratingsSort")}
                                                >
                                                    <option value="">Last 7 Days</option>
                                                    <option value="ascending">Last Month</option>
                                                    <option value="descending"> Last 6 Months</option>
                                                    <option value="descending"> All Time</option>
                                                </select>

                                                <div className='activity ms-3'>
                                                    <div className="btn-group" role="group" aria-label="Basic radio toggle button group">
                                                        <input type="radio" className="btn-check" name="btnradio" id="btnradio1" autoComplete="off" checked />
                                                        <label className="btn btn-outline-primary" htmlFor="btnradio1">Daily</label>

                                                        <input type="radio" className="btn-check" name="btnradio" id="btnradio3" autoComplete="off" />
                                                        <label className="btn btn-outline-primary" htmlFor="btnradio3">Cumulative</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col-12">
                            <div className="d-flex align-items-end">
                                <span style={{ color: "#000", fontSize: ' 34px', marginRight: '12px' }}>1</span>
                                <p style={{ color: "#ff6a27", fontSize: "17px", paddingBottom: '7px' }}>Messages exchanged</p>
                            </div>
                        </div>
                        <div className="col-12 mt-4">
                            <div style={{ width: "100%" }}>
                                <LineChart width={900} height={400} data={data} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
                                    <Line type="monotone" dataKey="uv" stroke="#ff6a27" />
                                    <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <Tooltip />
                                </LineChart>
                            </div>
                        </div>
                    </div>
                </div>


            </div >
        </>
    )
}

export default Activity
