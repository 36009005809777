import produce from "immer"
const initialState = {
    data: false,
    studioData: false
}

export const CloneReducer = (state = initialState, action) => {
    switch (action.type) {
        case "ADD_CLONE_DATA":
            return produce(state, (draft) => {
                draft.data = action.payload
            })

        case "ADD_CLONE_STUDIO":
            return produce(state, (draft) => {
                draft.studioData = action.payload
            })

        case "ON_CHNAGE_PURPOSE":
            return produce(state, (draft) => {
                draft.studioData.purpose = action.payload.value
            })

        case "SAVE_VOICE":
            return produce(state, (draft) => {
                draft.studioData.voice = action.payload.voice
            })

        default:
            return state
    }
}
