import React from 'react'
import tweet from "../../../../../assets/images/tweet.png"
import tiktok from "../../../../../assets/images/tiktok.png"
import instagram from "../../../../../assets/images/instagram.png"
import linkedin from "../../../../../assets/images/linkedin.png"
import single_file from "../../../../../assets/images/single_file.png"
import { AiOutlineClose } from 'react-icons/ai'
const SocialsModal = (props) => {
    return (
        <>
            <div className="content_modal">
                <div className='d-flex justify-content-between align-items-center mt-3' >
                    <h2 className=''>Upload Socials</h2>
                    <AiOutlineClose className='close_icon' fontSize={20} onClick={props.handleClose} />
                </div>

                <div className="container">
                    <div className="row">
                        <div className="col-3 mt-3">
                            <div className="website_pop">
                                <div className='website_upload' style={{ position: "relative" }}>
                                    <img src={tweet} alt="" />
                                    <div className="form-check" style={{ position: "absolute", top: "5px", left: "10px" }}>
                                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />

                                    </div>
                                </div>
                                <div>
                                    <p className='text-p3' style={{ marginTop: "3px", marginLeft: "3px" }}>Upload your X profile</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-3 mt-3">
                            <div className="website_pop">
                                <div className='website_upload' style={{ position: "relative" }}>
                                    <img src={instagram} alt="" />
                                    <div className="form-check" style={{ position: "absolute", top: "5px", left: "10px" }}>
                                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />

                                    </div>
                                </div>
                                <div>
                                    <p className='text-p3' style={{ marginTop: "3px", marginLeft: "3px" }}>Upload your Instagram</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-3 mt-3">
                            <div className="website_pop">
                                <div className='website_upload' style={{ position: "relative" }}>
                                    <img src={linkedin} alt="" />
                                    <div className="form-check" style={{ position: "absolute", top: "5px", left: "10px" }}>
                                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />

                                    </div>
                                </div>
                                <div>
                                    <p className='text-p3' style={{ marginTop: "3px", marginLeft: "3px" }}>Upload your LinkedIn</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 mt-4">
                            <ul style={{ listStyleType: "number", paddingLeft: "20px" }}>
                                <li style={{ fontSize: "13px", fontWeight: "600" }}> <span style={{ color: '#ff6a27' }}>Click here to request your Twitter data.</span></li>
                                <li className='mt-3' style={{ fontSize: "13px", fontWeight: "500", color: "#71717a" }}>After you receive your data export from Step 1, upload the ZIP file below.</li>
                            </ul>
                            <p className='mt-2 mb-3 ps-1'>
                                <strong style={{ color: "#71717a", fontSize: "13px" }}>You will only have to do this once as we can auto sync new tweets every week afterwards.</strong>

                            </p>
                        </div>
                        <div className="col-12">
                            <hr className='' style={{ opacity: "1", color: "#a1a1aa45" }} />

                        </div>

                        <div className="col-12">
                            <div className='upload_wrap mt-2'>
                                <div className='upload_content_wrap'>
                                    <img src={single_file} alt="" style={{ height: "98px" }} />
                                    <input type="file" />
                                    <h3 >Upload Files or Images</h3>
                                    <p className="text-p3 text-center"><span className="text-brand">Click to browse</span> or drag and drop</p>
                                </div>


                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default SocialsModal