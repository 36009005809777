import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import { themeColor } from '../../../../Global/Global';
import { onUploadMedia } from '../../../../Redux/Actions/CommonActions';
import dummyProfile from '../../../../assets/images/dummyImage.png'
import { onChangeCloneSettingProfile, onSaveCloneProfile } from '../../../../Redux/Actions/CloneSettingAction';

const CloneProfile = (props) => {

    const { cloneProfile } = props.settingData;
    let dummy = "https://images.pexels.com/photos/2169434/pexels-photo-2169434.jpeg"

    const dispatch = useDispatch();
    const [percent, setPercent] = useState(0);
    const [loader, setLoader] = useState({
        save: false,
        upload: false
    });

    const getProfileImage = (url, type) => {
        dispatch(onChangeCloneSettingProfile(type, url))
    }

    const onInputChange = (e) => {
        let allowedExt = ['image/png', 'image/jpg', 'image/jpeg']
        const formData = new FormData()
        if (e.target.files.length > 0) {
            if (allowedExt.includes(e.target.files[0].type)) {
                if (e.target.files[0].size < 5000000) {
                    formData.append('upload_type', "images")
                    formData.append('file', e.target.files[0])
                    setLoader({ ...loader, upload: true })
                    dispatch(onUploadMedia(formData, getProfileImage, loader, setLoader, setPercent, "profile"))
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Max allowed size for images is 5MB!',
                        confirmButtonColor: themeColor
                    })
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'You have Selected Invalid File Type!',
                    confirmButtonColor: themeColor
                })
            }
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        dispatch(onChangeCloneSettingProfile(name, value))
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoader({ ...loader, save: true })
        let data = { ...cloneProfile }
        dispatch(onSaveCloneProfile(data, loader, setLoader))
    }

    return (
        <>

            <div className="settings_wrap ps-3">
                <form action="" onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-12">
                            <div className="settings_head">
                                <h2>Clone profile</h2>
                            </div>
                            <div className="setting_upload_img mt-5">
                                <span>
                                    {loader.upload ?
                                        <div className='profileLoader'>
                                            <i className='fa fa-spin fa-spinner' style={{ color: themeColor, fontSize: "23px" }} />
                                        </div>
                                        : <img src={cloneProfile.profile || dummyProfile} alt="no image" />
                                    }

                                </span>
                                <div className="setting_upload_input ms-4">
                                    <button className='off-btn'>Upload new image</button>
                                    <input
                                        type="file"
                                        accept='image/*'
                                        onChange={onInputChange}
                                    />
                                </div>
                            </div>


                        </div>
                        <div className="col-7 mt-5">
                            <div className="settings_content">
                                <h3 className='mb-1'>Clone Name</h3>
                                <p className='mb-2 para_text2 f-13'>This is your unique url. Ideally, this should match your name and/or your handle on another</p>
                                <div className="setting_input">
                                    <div className="input-outer">
                                        <input
                                            className="common-inp alt"
                                            type="text"
                                            placeholder="Dana"
                                            name='name'
                                            value={cloneProfile.name}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="settings_content mt-5">
                                <h3 className='mb-1'>Clone Handle</h3>
                                <p className='mb-2 para_text2 f-13'>This is your unique url. Ideally, this should match your name and/or your handle on another site like X/Instagram.</p>
                                <div className="setting_input">
                                    <div className="input-outer">
                                        <div className="input-group" style={{ flexWrap: "Nowrap" }}>
                                            <span class="input-group-text" id="basic-addon3">https://example.com/</span>
                                            <input
                                                className="common-inp alt"
                                                type="text"
                                                placeholder="Url"
                                                name='handle'
                                                value={cloneProfile.handle}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="setting_btn text-end mt-3">
                                <button type='submit' disabled={loader.save} className='theme-btn me-0'><span>{loader.save ? <>Saving <i className='fa fa-spin fa-spinner' /></> : "Save"}</span></button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}

export default CloneProfile
