import { commonAxios } from "../../Global/CommonAxios"
import { setAlert } from "./AlertActions"

export const onFetchNewSetting = (data, loader, setLoader) => (dispatch, getState) => {
    commonAxios("", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch({ type: "ADD_CLONE_SETTING_DATA", payload: res.data })
            }
            setLoader({ ...loader, fetch: false });
        }).catch((err) => {
            console.log(err.msg)
            dispatch(setAlert(err.msg, "danger"))
            setLoader({ ...loader, fetch: false });
        })
}
export const onSaveCloneProfile = (data, loader, setLoader) => (dispatch, getState) => {
    commonAxios("", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({ ...loader, save: false });
        }).catch((err) => {
            console.log(err.msg)
            dispatch(setAlert(err.msg, "danger"))
            setLoader({ ...loader, save: false });
        })
}
export const onSaveCloneBio = (data, loader, setLoader) => (dispatch, getState) => {
    commonAxios("", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({ ...loader, save: false });
        }).catch((err) => {
            console.log(err.msg)
            dispatch(setAlert(err.msg, "danger"))
            setLoader({ ...loader, save: false });
        })
}

// no axios call
export const onChangeCloneSettingProfile = (name, value) => (dispatch) => {
    dispatch({ type: "CHANGE_CLONE_SETTING_PROFILE", payload: { name, value } });
}
export const onChangeCloneSettingBio = (name, value) => (dispatch) => {
    dispatch({ type: "CHANGE_CLONE_SETTING_BIO", payload: { name, value } });
}