import React, { useState } from 'react';

const Notification = () => {
    const [show, setShow] = useState(false)
    const handleChangeEnable = () => {
        setShow(!show)
    }
    return (
        <div>
            <div className="notifications_wrap ps-3">
                <div className="row">
                    <div className="col-12">
                        <div className="notification_head">
                            <h2>Notifications</h2>
                        </div>
                    </div>
                    <div className="col-lg-7 mt-3">
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="info mt-3 col-10">
                                <h3 className='f-16'>Email When Clone Can't Answere </h3>
                                <p className="para_text2 f-13" style={{ fontWeight: "500" }}>Be alert if your clone can't answer a user question</p>
                            </div>
                            <div className="opt-select">
                                <label className="switch">
                                    <input
                                        type="checkbox"
                                    // checked={selectedSlide.faceNarration.enable}
                                    // onChange={handleChangeEnable}
                                    />
                                    <span className="slider round" style={{ background: "#e4e4e7" }}></span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7 mt-3">
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="info mt-3 col-10">
                                <h3 className='f-16'>Clone Activity Report </h3>
                                <p className="para_text2 f-13">Email report of your clone activity with user</p>
                            </div>
                            <div className="opt-select">
                                <label className="switch">
                                    <input
                                        type="checkbox"
                                        // checked={selectedSlide.faceNarration.enable}
                                        onChange={handleChangeEnable}
                                    />
                                    <span className="slider round" style={{ background: "#e4e4e7" }}></span>
                                </label>
                            </div>
                        </div>
                    </div>
                    {show ?
                        <div>
                            <div className="col-lg-7 mt-3">
                                <div className="wrap">
                                    <div className="info mt-3">
                                        <h3 >Frequency</h3>
                                    </div>
                                    <div className="slectBoxWrap">
                                        <select className='frequencyBox '>
                                            <option value="Weekly">Weekly</option>
                                            <option value="monthly">Monthly</option>
                                            <option value="one">One</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-7 mt-3">
                                <div className="wrap">
                                    <div className="info mt-3">
                                        <h3>What to Include</h3>
                                    </div>
                                    <div className="slectBoxWrap">
                                        <form action="#">
                                            <div class="item d-flex align-items-center mt-3">
                                                <input type="checkbox" id="message" name="message" value="message" />
                                                <label for="message" class="ps-2">Message Exchanged</label>
                                            </div>
                                            <div class="item d-flex align-items-center mt-2">
                                                <input type="checkbox" id="conversation" name="conversation" value="conversation" />
                                                <label for="conversation" class="ps-2">Conversation</label>
                                            </div>
                                            <div class="item d-flex align-items-center mt-2">
                                                <input type="checkbox" id="unansweredQuestions" name="unansweredQuestions" value="unansweredQuestions" />
                                                <label for="unansweredQuestions" class="ps-2">Unanswered Questions</label>
                                            </div>
                                            <div class="item d-flex align-items-center mt-2">
                                                <input type="checkbox" id="moneyEarned" name="moneyEarned" value="moneyEarned" />
                                                <label for="moneyEarned" class="ps-2">Money earned</label>
                                            </div>
                                            <div class="item d-flex align-items-center mt-2">
                                                <input type="checkbox" id="uniqueUser" name="uniqueUser" value="uniqueUser" />
                                                <label for="uniqueUser" class="ps-2">Unique User</label>
                                            </div>
                                            <div class="item d-flex align-items-center mt-2">
                                                <input type="checkbox" id="alertTriggered" name="alertTriggered" value="alertTriggered" />
                                                <label for="alertTriggered" class="ps-2">Alert Triggered</label>
                                            </div>
                                        </form>


                                    </div>
                                </div>
                                <div className="save_btn mt-5 d-flex justify-content-end">
                                    <button className='theme-btn me-0'> <span>Save</span> </button>
                                </div>
                            </div>
                        </div>
                        : ""

                    }



                </div>
            </div>
        </div>
    )
}

export default Notification
