import React from 'react';
import { IoIosArrowForward } from "react-icons/io";
import { CiGlobe } from "react-icons/ci";
import website from '../../../../../src/assets/images/SocialIcons/wide.png';
import { MdSms } from "react-icons/md";
import { FaWhatsapp } from "react-icons/fa";
import { BsStackOverflow } from "react-icons/bs";
import { FaDiscord } from "react-icons/fa";
import { FaTelegram } from "react-icons/fa";
import { FaMicrochip } from "react-icons/fa6";
const Integrations = () => {
    return (
        <div>
            <div className="settings_wrap ps-3">
                <div className="row">
                    <div className="col-12">
                        <div className="settings_head">
                            <h2>Integrations</h2>
                        </div>
                    </div>
                    <div className="integrations_body">
                        <div className="col-lg-7">
                            <div className="connected_app_wrap">
                                <h3 className='py-4'>Connected Apps</h3>
                                <div className='band_wrapper mt-4'>
                                    <div className="row align-items-center justify-content-between ">
                                        <div className="col-11">
                                            <div className="d-flex align-items-center ">
                                                <div className="img_wrap">
                                                    <CiGlobe className='int_icon' />
                                                </div>
                                                <div className="details ps-3">
                                                    <h3 className="name">Website Integration</h3>
                                                    <p className='para_text2 f-13'>Embed your clone on your website</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-1">
                                            <IoIosArrowForward />
                                        </div>
                                    </div>
                                </div>
                                <div className='band_wrapper mt-4'>
                                    <div className="row align-items-center justify-content-between ">
                                        <div className="col-11">
                                            <div className="d-flex align-items-center ">
                                                <div className="img_wrap">
                                                    <MdSms className='int_icon' />
                                                </div>
                                                <div className="details ps-3">
                                                    <h3 className="name">SMS Integration</h3>
                                                    <p className='para_text2 f-13'>Give your clone a phone number</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-1">
                                            <IoIosArrowForward />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="all_app_wrap">
                                <h3 className='py-4'>All Apps</h3>
                                <div className='band_wrapper mt-4'>
                                    <div className="row align-items-center justify-content-between ">
                                        <div className="col-11">
                                            <div className="d-flex align-items-center ">
                                                <div className="img_wrap">
                                                    <FaWhatsapp className='int_icon' />
                                                </div>
                                                <div className="details ps-3">
                                                    <h3 className="name">WhatsApp Integration</h3>
                                                    <p className='para_text2 f-13'>Add your clone to WhatsApp</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-1">
                                            <IoIosArrowForward />
                                        </div>
                                    </div>
                                </div>
                                <div className='band_wrapper mt-4'>
                                    <div className="row align-items-center justify-content-between ">
                                        <div className="col-11">
                                            <div className="d-flex align-items-center ">
                                                <div className="img_wrap">
                                                    <BsStackOverflow className='int_icon' />
                                                </div>
                                                <div className="details ps-3">
                                                    <h3 className="name">Slack Integration</h3>
                                                    <p className='para_text2 f-13'>Add your clone to Slack</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-1">
                                            <IoIosArrowForward />
                                        </div>
                                    </div>
                                </div>
                                <div className='band_wrapper mt-4'>
                                    <div className="row align-items-center justify-content-between ">
                                        <div className="col-11">
                                            <div className="d-flex align-items-center ">
                                                <div className="img_wrap">
                                                    <FaDiscord className='int_icon' />
                                                </div>
                                                <div className="details ps-3">
                                                    <h3 className="name">Discord Integration</h3>
                                                    <p className='para_text2 f-13'>Add your clone to Discord Server</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-1">
                                            <IoIosArrowForward />
                                        </div>
                                    </div>
                                </div>
                                <div className='band_wrapper mt-4'>
                                    <div className="row align-items-center justify-content-between ">
                                        <div className="col-11">
                                            <div className="d-flex align-items-center ">
                                                <div className="img_wrap">
                                                    <FaTelegram className='int_icon' />
                                                </div>
                                                <div className="details ps-3">
                                                    <h3 className="name">Telegram Integration</h3>
                                                    <p className='para_text2 f-13'>Add your clone to Telegram</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-1">
                                            <IoIosArrowForward />
                                        </div>
                                    </div>
                                </div>
                                <div className='band_wrapper mt-4 prodigy_feature '>
                                    <div className="row align-items-center justify-content-between ">
                                        <div className="col-11">
                                            <div className="d-flex align-items-center ">
                                                <div className="img_wrap">
                                                    <FaMicrochip className='int_icon' />
                                                </div>
                                                <div className='d-flex flex-wrap'>
                                                    <div className="details ps-3">
                                                        <h3 className="name">Clone API</h3>
                                                        <p className='para_text2 f-13'>Access your clone anywhere</p>
                                                    </div>
                                                    <button className='theme-btn f-13 ms-3 mt-md-0 mt-sm-2 mt-2' style={{ width: "auto" }}><span>Prodigy feature</span> </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-1">
                                            <IoIosArrowForward />
                                        </div>
                                    </div>

                                </div>
                                <div className='band_wrapper mt-4'>
                                    <div className="row align-items-center justify-content-between ">
                                        <div className="col-11">
                                            <div className="d-flex align-items-center ">
                                                <div className="img_wrap">
                                                    <img src={website} alt="insta Logo" className='img-fluid' />
                                                </div>
                                                <div className="details ps-3">
                                                    <h3 className="name">Zapier Connector</h3>
                                                    <p className='para_text2 f-13'>Receive events about your clones users</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-1">
                                            <IoIosArrowForward />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Integrations
