import React, { useState } from 'react'
import { FaPlus } from 'react-icons/fa'
import useAdd from '../../../../../src/assets/images/SocialIcons/pig.png';


const Monetization = () => {
    const [sliderValue, setSliderValue] = useState(1);

    const handleSliderChange = (event) => {
        const value = parseInt(event.target.value);
        setSliderValue(value);
    };
    return (
        <>

            <div className="studio_setting ps-3">
                <div className="row">
                    <div className="col-12" style={{ maxWidth: "575px" }}>
                        <div className="settings_head">
                            <h2>Monetization</h2>
                        </div>

                        <div>

                            <div className="Usage_wrap mt-5" style={{ background: "#fff" }}>

                                <div>

                                    <div className='d-flex justify-content-between align-items-center  mt-1 '>
                                        <div className='d-flex flex-column mt-1 '>
                                            <p className='f-13' style={{ color: "#a1a1aa", fontWeight: "500" }}>Total Revenue</p>
                                            <p style={{ color: "#000", fontWeight: "500", fontSize: "27px" }}>$0</p>
                                        </div>
                                        <button className='theme-btn me-0 f-13'><span>Manage Plans </span></button>
                                    </div>
                                    <hr className='' style={{ opacity: "1", color: "#a1a1aa45", marginTop: "25px", borderStyle: "dashed" }} />

                                    <div className='d-flex justify-content-between align-items-center  mt-1 '>
                                        <div className='d-flex flex-column mt-1 '>
                                            <p className='f-13' style={{ color: "#a1a1aa", fontWeight: "500" }}>Total Revenue</p>
                                            <p style={{ color: "#000", fontWeight: "500", fontSize: "27px" }}>$0</p>
                                        </div>
                                        <div className='d-flex flex-column mt-1 '>
                                            <p className='f-13' style={{ color: "#a1a1aa", fontWeight: "500" }}>aiduals</p>
                                            <p style={{ color: "#000", fontWeight: "500", fontSize: "27px" }}>15%</p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className='Usage_wrap_bottom'>
                                <div className='d-flex  justify-content-between align-items-center'>
                                    <div className='d-flex  align-items-center'>
                                        <span><img src={useAdd} alt="" /></span>
                                        <p className='f-13 ms-3' style={{ color: "#71717a", fontWeight: "500" }}><span className='text-dark'>Want more messages?</span><br />

                                            Add more messages to your plan</p>
                                    </div>
                                    <button className='theme-btn me-0 f-13 mt-1'><span>Upgrade </span></button>
                                </div>
                            </div>
                        </div>

                        <div className='payment_account '>
                            <h2 style={{ color: "#18181b", fontSize: "16px", fontWeight: "600" }}>Payment Account</h2>
                            <p className='f-13 mt-2 mb-2' style={{ color: "#71717a" }}>  Setup an account with Stripe to manage your payouts.</p>
                            <button className='theme-btn me-0 f-13'><span>Finish Onboarding </span></button>
                        </div>
                        <hr className='' style={{ opacity: "1", color: "#a1a1aa45", marginTop: "75px" }} />
                    </div>
                    <div className='Paywall mt-5'>
                        <h2 style={{ color: "#18181b", fontSize: "16px", fontWeight: "600" }}>Messages before Paywall</h2>
                        <p className='f-13 mt-2 mb-2' style={{ color: "#71717a" }}> How many messages a user can send before the paywall pops up</p>

                        <div className='inp_radio_wrap' style={{ maxWidth: "576px" }}>
                            <span style={{ color: "#ff6a27", fontWeight: "600" }}>{sliderValue}</span>

                            <input
                                type="range"
                                className="mt-2"
                                min="0"
                                max="10"
                                id="customRange2"
                                value={sliderValue}
                                onChange={handleSliderChange}
                            />
                            <p className='f-13' style={{ color: "#a1a1aa", fontWeight: "500" }}>Messages before login required is set to <span style={{ color: "#ff6a27" }}>10</span>. You can update it <span style={{ color: "#ff6a27" }}>here</span> </p>
                        </div>
                    </div>
                </div>
            </div >

        </>
    )
}
export default Monetization
